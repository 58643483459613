@import "./vars/vars";
@import "./mixins";
@import "../templates/social-list.scss";

.aroma-footer {
  background: $color_white_smoke;
  margin-top: 60px;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    @media (min-width: $desktop_min) {
      margin: 40px 51px 0 10px;

      a > img {
        width: 173px;
        height: 85px;
      }
    }
  }

  &__column {
    position: relative;
    padding: 43px 30px 21px;
    border-left: 1px solid $color_white;

    &:first-child {
      padding-left: 0;
      border: 0;
    }

    .line {
      display: block;
      position: absolute;
      top: 0;
      left: 284px;
      width: 1px;
      height: 194px;
      background-color: $color_white;

      @media (max-width: $tablet_max) {
        display: none;
      }
    }
  }

  &__links {
    display: flex;
    gap: 61px;
  }

  &__links-wrap {
    display: flex;
    align-items: baseline;

    span {
      color: #545454;
    }

    ul {
      margin-left: 20px;
      text-transform: uppercase;
      max-width: 190px;

      @media (min-width: $desktop_min) {
        max-width: 214px;
      }

      li:not(:last-child) {
        margin-bottom: 8px;

        @media (min-width: $desktop_min) {
          margin-bottom: 10px;
        }
      }
    }

    a {
      @include h6;
      letter-spacing: 0.03em;
      color: #545454;

      &:hover {
        color: $color_grey_2;
      }
    }
  }

  &__copyright {
    padding: 20px 0;
    align-items: center;
    border-top: 1px solid $color_white;

    @media (min-width: $desktop_min) {
      padding: 24px 0;

      span:first-child {
        margin-right: 30px;
      }
    }

    a {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 1px;
        width: 100%;
        background: $color_grey;
      }
    }
    span {
      @include h6;
      color: $color_grey_2;
      letter-spacing: 0.03em;
    }
    .txt__small-addition {
      margin-right: 140px;
      @media (max-width: $tablet_max) {
        margin-right: 50px;
      }
      @media (max-width: 1000px) {
        margin-right: 150px;
      }
      @media (max-width: $phone_max) {
        margin-right: 0;
      }
    }
  }

  &__socials {
    padding: 45px 10px 0 50px;

    @media (min-width: 1201px) and (max-width: 1218px) {
      padding: 45px 10px 0 27px;
    }
  }
}

@media (max-width: $tablet_max) {
  .aroma-footer {
    &__row {
      flex-wrap: wrap;
    }
    &__column {
      width: auto;
      padding: 30px 0;
    }
    &__logo {
      margin-top: 30px;

      a > img {
        width: 121px;
        height: 60px;
      }
    }
    &__logo,
    &__socials {
      border-bottom: 1px solid $color_white;
    }
    &__column:first-child {
      border-bottom: 1px solid $color_white;
    }
    &__links {
      display: flex;
      position: relative;
      gap: 40px;

      @media (max-width: $phone_max) {
        gap: 0;
      }

      &:nth-of-type(2) {
        border: none;
        padding-left: 0;
      }
      &:nth-of-type(3) {
        padding-right: 0;
      }

      &-wrap {
        &:first-child {
          max-width: 225px;

          &::after {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 245px;
            width: 1px;
            height: 184px;
            background-color: $color_white;

            @media (max-width: $phone_max) {
              display: none;
            }
          }
        }

        ul {
          width: 160px;
        }
      }
    }
    &__logo,
    &__socials {
      width: 50%;
    }
    &__logo {
      order: -2;
    }
    &__socials {
      order: -1;
      display: flex;
      justify-content: flex-end;
      border-left: 0;

      .socials-list {
        height: 60px;

        li {
          height: 60px;
        }
      }
    }

    &__copyright {
      padding: 33px 0 34px;

      span:first-child {
        margin-right: 30px;
      }
    }
  }
}

@media (max-width: $phone_max) {
  .aroma-footer {
    &__column {
      width: auto;
      border: none;
      padding: 24px 0;
    }

    &__logo {
      margin-top: 22px;

      a > img {
        width: 108px;
        height: 54px;
      }
    }

    &__socials {
      padding: 23px 0;
      width: 50%;
      border-bottom: 1px solid $color_white;

      .socials-list {
        height: 52px;

        li {
          height: 52px;
        }

        &__link {
          width: 52px;
          height: 52px;
        }
      }
    }

    &__column:first-child {
      border-bottom: 1px solid $color_white;
    }

    &__links {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;

      &:nth-of-type(2n) {
        border: none;
        padding-right: 10px;
      }

      &:nth-of-type(3) {
        order: 1;
      }
    }

    &__links-wrap {
      display: flex;
      flex-direction: column;

      > span {
        margin-bottom: 10px;
      }
      ul {
        margin-left: 0;
      }

      &:first-child {
        margin-bottom: 20px;
      }
    }

    &__copyright {
      padding: 20px 0;

      > span {
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }
}

.cities {
  width: 107px;
  margin-right: 10px;

  @media (max-width: $phone-max) {
    ul {
      width: 107px;
    }
  }

  @media (min-width: $tablet_min) {
    width: 181px;

    margin-left: 20px;
    margin-right: 0;

    &::after {
      display: none !important;
    }
  }

  @media (min-width: $desktop_min) {
    width: 135px;
    margin-left: 0;
    margin-right: 80px;
  }
}
