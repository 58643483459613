@import "../uikit/vars/vars";
@import "../uikit/mixins";

.select2-container {
  cursor: pointer;
  &--open {
    z-index: 16;
  }
  .select2-selection--single {
    height: auto;
    .select2-selection__rendered {
      padding-left: 0;
      @include h6;
      letter-spacing: 0.03em;
      color: $color_black;
    }
  }
}

.select2-dropdown {
  border: 0;
  z-index: 1;
  @media (max-width: $phone-max) {
    z-index: 4;
  }
}
.select2-results__option {
  border-bottom: 1px solid $color_grey_light;
  border-left: 1px solid $color_grey_light;
  border-right: 1px solid $color_grey_light;
}

// select2-size
.select2-container--select2-size {
  color: $color_grey;
  .select2-selection--single {
    border: 0;
    .select2-selection__arrow {
      top: 50%;
      right: 4px;
      transform: translateY(-50%);
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    border: 1px solid $color_dark_gold;
    background: transparent;
    color: $color_grey;
  }
  .select2-selection--single {
    width: 100%;
    padding: 6px;
    height: auto;
    outline: 1px solid transparent;
    background-color: $color_white;
    .select2-selection__arrow {
      position: absolute;
      height: 26px;
      width: 20px;
      top: 50%;
      right: 4px;
      transform: translateY(-50%);
      b {
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
        border-color: $color_dark_gold transparent transparent transparent;
      }
    }
  }
  &.select2-container--open {
    .select2-selection--single {
      .select2-selection__arrow {
        b {
          border-color: transparent transparent $color_dark_gold transparent;
          border-width: 0 4px 5px 4px;
        }
      }
    }
  }
  .select2-img-txt {
    display: flex;
    align-items: center;
    padding: 2px 10px 2px 10px;
    img {
      width: 31px;
      height: 34px;
      margin-right: 10px;
    }
  }
  &.select2-container--open {
    .select2-selection--single {
      .select2-selection__arrow b {
        border-color: transparent transparent $color_dark_gold transparent;
      }
    }
  }
  .select2-results {
    > .select2-results__options {
      max-height: 205px;
      box-shadow: 0px 15px 35px rgba(228, 209, 192, 0.5);
    }
  }
}

// select2 default
.select2-container--select2-default {
  .select2-selection--single {
    height: 50px;
    background: $color_white_smoke;
    border-radius: 0;
    padding: 16px 40px 18px 20px;
    border: 1px solid transparent;
    &:hover {
      border-color: $color_dark_grey_2 !important;
    }
    .select2-selection__arrow {
      position: absolute;
      height: 26px;
      width: 20px;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      b {
        border-style: solid;
        border-width: 5px 4px 0 4px;
        border-color: $color_dark_grey_2 transparent transparent transparent;
        position: absolute;
        left: 50%;
        top: 45%;
        transition: transform 0.2s;
      }
    }
    .select2-selection__rendered {
      @include custom-font-inter(700, 14px, 18px);
      text-transform: uppercase;
      .select2-selection__placeholder {
        @include custom-font-inter(400, 14px, normal);
        text-transform: none;
      }
    }
  }
  &.select2-container--open {
    .select2-selection__arrow b {
      transform: rotate(180deg);
    }
  }
  .select2-dropdown {
    margin-top: 10px;
    box-shadow: $shadow_dropdown;
  }
  .select2-results__option {
    @include custom-font-inter(400, 14px, 18px);
    padding: 7px 30px;
    text-transform: uppercase;
    border: none;
    color: $color_dark_grey_2;
    transition: background-color 0.2s linear;
    &:hover {
      color: $color_dark_grey;
    }
  }
}
select[data-select2-type="select2-default"].selected
  + .select2-container--select2-default
  .select2-selection--single {
  border-color: $color_grey_light;
}
.select2-container--select2-default.select2-container--focus {
  .select2-selection--single {
    border-color: $color_dark_grey_2 !important;
  }
}

// select2 rounded
.select2-container--select2-rounded {
  .select2-selection--single {
    height: auto;
    background: $color_white;
    border-radius: 0;
    padding: 3px 0;
    border-radius: 20px;
    .select2-selection__arrow {
      top: 50%;
      right: 4px;
      transform: translateY(-50%);
    }
    .select2-selection__arrow {
      position: absolute;
      height: 24px;
      width: 24px;
      top: 10.5px;
      right: 4px;
      transform: translateY(-50%);
      b {
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
        border-color: $color_dark_gold transparent transparent transparent;
      }
    }
    .select2-selection__rendered {
      padding-left: 0;
      font-weight: 700;
      white-space: break-spaces;
      text-transform: uppercase;
    }
  }
  &.select2-container--open {
    .select2-selection--single {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .select2-selection__arrow {
        b {
          border-color: transparent transparent $color_dark_gold transparent;
          border-width: 0 4px 5px 4px;
        }
      }
    }
  }
  .select2-results {
    background-color: $color_white_smoke;
    padding: 24px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 4px 10px 0px #74747424;

    @media (max-width: $phone-max) {
      padding: 24px 20px;
    }
  }
  .select2-results__option {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    @include custom-font-inter(400, 14px, 18px);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    transition: all 0.3s linear;
    padding: 0;
    border: 0;
    &:hover {
      font-weight: 700;
    }

    @media (max-width: $phone-max) {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

// select2 grey-picker
.select2-container--select2-grey-picker {
  .select2-selection--single {
    height: auto;
    background: #f3f2ef;
    border-radius: 0;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 30px 0 30px;
    .select2-selection__arrow {
      top: 50%;
      right: 4px;
      transform: translateY(-50%);
    }
    .select2-selection__arrow {
      position: absolute;
      height: 26px;
      width: 20px;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      b {
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
        border-color: $color_dark_gold transparent transparent transparent;
      }
    }
    .select2-selection__rendered {
      padding-left: 0;
      font-weight: 700;
      text-transform: uppercase;
      .select2-selection__placeholder {
        font-weight: 400;
        text-transform: none;
        font-size: 12px;
      }
    }
  }
  &.select2-container--open {
    .select2-selection--single {
      .select2-selection__arrow {
        b {
          border-color: transparent transparent $color_dark_gold transparent;
          border-width: 0 4px 5px 4px;
        }
      }
    }
  }
  .select2-results {
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.15);
  }
  .select2-results__option {
    padding: 8px 30px;
    text-transform: uppercase;
    transition: all 0.3s linear;
    border: 0;
    background: #f3f2ef;
    &:hover {
      // border-color: $color_brown;
      background: $color_dark_gold;
      color: $color_white;
    }
  }
}

// Color Select
.select2-container--select2-color {
  .select2-selection--single {
    background: $color_white;
    height: 50px;
    padding: 18px 35px 18px 12px;
    color: $color_dark_grey_2;
    border: 1px solid $color_grey_light;
    transition: border 0.2s;
    display: flex;
    align-items: center;
    &:hover {
      border-color: $color_dark_grey_2;
    }
    .select2-selection__rendered {
      @include custom-font-inter(700, 14px, 18px);
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 12px;
      .select2-selection__placeholder {
        @include custom-font-inter(300, 14px, normal);
        text-transform: none;
        padding-left: 4px;
      }
    }
    .select2-selection__arrow {
      position: absolute;
      height: 26px;
      width: 20px;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      b {
        border-style: solid;
        border-width: 5px 4px 0 4px;
        border-color: $color_dark_grey_2 transparent transparent transparent;
        position: absolute;
        left: 50%;
        top: 45%;
        transition: transform 0.2s;
      }
    }
  }
  &.select2-container--focus {
    .select2-selection--single {
      border-color: $color_dark_grey_2;
    }
  }
  &.select2-container--open {
    .select2-selection__arrow b {
      transform: rotate(180deg);
    }
  }
  .select2-results__option {
    @include custom-font-inter(700, 14px, 18px);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    height: 50px;
    gap: 12px;
    padding: 18px 35px 18px 12px;
    cursor: pointer;

    &[aria-disabled="true"] {
      order: 1;
      opacity: 0.6;
      background-color: $color_grey_3;
      cursor: not-allowed;
    }
  }
  .select2-results {
    box-shadow: $shadow_dropdown;
  }
  .select2-color-box {
    width: 24px;
    height: 24px;
    display: block;
  }
  .select2-results__options {
    display: grid;
    height: fit-content;
    max-height: 150px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $color_dark_grey_2 transparent;
  }
}

// Select with search
.select2-container--select2-search {
  border: 1px solid transparent;
  .select2-selection--single {
    height: 50px !important;
    background: $color_white_smoke;
    border-radius: 0;
    padding: 16px 40px 18px 20px;
    .select2-selection__arrow {
      position: absolute;
      height: 26px;
      width: 20px;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      b {
        border-style: solid;
        border-width: 5px 4px 0 4px;
        border-color: $color_dark_grey_2 transparent transparent transparent;
        position: absolute;
        left: 50%;
        top: 45%;
        transition: transform 0.2s;
      }
    }
    .select2-selection__rendered {
      @include custom-font-inter(700, 14px, 18px);
      text-transform: uppercase;
      .select2-selection__placeholder {
        @include custom-font-inter(400, 14px, normal);
        text-transform: none;
      }
    }
  }
  &.select2-container--open {
    .select2-selection__arrow b {
      transform: rotate(180deg);
    }
  }
  .select2-dropdown {
    box-shadow: $shadow_dropdown;
    margin-top: 1px;
    @media (max-width: $phone_max) {
      display: none;
    }
    &.mobile-fullscreen {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw !important;
      height: 100vh;
    }
  }
  .select2-results__options {
    max-height: 255px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $color_dark_grey_2 transparent;
    @media (max-width: $phone_max) {
      max-height: calc(100vh - 70px);
    }
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color_dark_grey_2;
    }
    .select2-results__message {
      @include custom-font-inter(300, 16px, 23px);
      text-transform: none;
    }
  }
  .select2-results__option {
    padding: 16px 20px;
    text-transform: uppercase;
    color: $color_dark_grey_2;
    @media (max-width: $phone_max) {
      border: none;
    }
    span:first-child {
      display: block;
      @include custom-font-inter(700, 14px, 18px);
    }
    span:nth-child(2) {
      display: block;
      @include custom-font-inter(400, 10px, 18px);
      letter-spacing: 0.3px;
      margin-top: 4px;
    }
  }

  .select2-search--dropdown {
    position: relative;
    padding: 0;
    border-bottom: 1px solid $color_grey_light;
    border-left: 1px solid $color_grey_light;
    border-right: 1px solid $color_grey_light;
    background-color: $color_white;
    &::before {
      display: block;
      content: url("../../images/icons/search-grey.svg");
      position: absolute;
      top: 29%;
      left: 20px;
      height: 24px;
      @media (max-width: $phone_max) {
        display: none;
      }
    }
    &::after {
      display: none;
      content: url("../../images/icons/search-black.svg");
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      height: 24px;
      @media (max-width: $phone_max) {
        display: block;
      }
    }
    input {
      @include custom-font-inter(300, 16px, 23px);
      padding: 16px 20px 16px 50px;
      background-color: $color_white;
      @media (max-width: $phone_max) {
        @include custom-font-inter(400, 22px, 28px);
        padding: 20px 50px 20px 65px;
      }
      &::placeholder {
        @include custom-font-inter(400, 14px, normal);
        color: $color_grey_2;
        @media (max-width: $phone_max) {
          font-size: 22px;
          line-height: 28px;
        }
      }
      &::-webkit-textfield-decoration-container {
        background-color: $color_white !important;
        &::-webkit-search-decoration {
          display: none !important;
        }
      }
      &::-webkit-search-decoration {
        display: none !important;
      }
    }
    .select-close-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      border: none;
      background-color: transparent;
      &::before {
        display: block;
        content: url("../../images/icons/close-black.svg");
        height: 24px;
      }
    }
  }

  &.select2-container--focus,
  &.select2-container--open,
  &:hover {
    border: 1px solid $color_dark_grey_2;
  }
}
select[data-select2-type="select2-search"].selected
  + .select2-container--select2-search
  .select2-selection--single {
  border: 1px solid $color_grey_light;
}

// Volume Select
.select2-container--select2-volume {
  .select2-selection--single {
    background: $color_white;
    height: 50px;
    padding: 16px 40px 16px 16px;
    color: $color_dark_grey_2;
    border: 1px solid $color_grey_light;
    transition: border 0.2s;
    display: flex;
    align-items: center;
    @media (max-width: $phone_max) {
      height: 40px;
      padding: 12px 40px 12px 8px;
    }
    &:hover {
      border-color: $color_dark_grey_2;
    }
    .select2-selection__rendered {
      @include custom-font-inter(700, 14px, 18px);
      text-transform: uppercase;
      @media (max-width: $phone_max) {
        @include custom-font-inter(400, 12px, normal);
        text-transform: none;
      }
    }
    .select2-selection__arrow {
      position: absolute;
      height: 26px;
      width: 20px;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      b {
        border-style: solid;
        border-width: 4px 3px 0 3px;
        border-color: $color_dark_grey_2 transparent transparent transparent;
        position: absolute;
        left: 50%;
        top: 45%;
        transition: transform 0.2s;
      }
    }
  }
  &.select2-container--focus {
    .select2-selection--single {
      border-color: $color_dark_grey_2;
    }
  }
  &.select2-container--open {
    .select2-selection__arrow b {
      transform: rotate(180deg);
    }
  }
  .select2-results__option {
    @include custom-font-inter(700, 14px, 18px);
    text-transform: uppercase;
    height: 50px;
    padding: 16px;
    cursor: pointer;
    @media (max-width: $phone_max) {
      @include custom-font-inter(400, 12px, normal);
      text-transform: none;
      height: 40px;
      padding: 12px 8px;
    }
  }
  .select2-results {
    box-shadow: $shadow_dropdown;
  }
}
