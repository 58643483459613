@import "../uikit/vars/vars";

.res-message {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    max-width: 220px;
    bottom: 20px;
    left: 20px;
    border-radius: 5px;
    padding: 10px;
    z-index: 15;
    font-family: $inter;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    @media (max-width: $phone_max) {
      left: 15px;
      bottom: 20px;
    }
    p {
      color: $color-white;
      @media (max-width: $phone_max) {
        font-size: 14px;
      }
    }
    &.error {
      background-color: $color_red_bg;
    }
    &.success {
      background-color: $color_green_2;
    }
    &.visible {
      visibility: visible;
      opacity: 1;
      animation: slideInLeft 0.5s;
    }
  }
  