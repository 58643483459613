@import "../uikit/vars/vars";

.socials-list {
  display: flex;
  align-items: center;
  &__item:not(:first-child) {
    margin-left: 8px;
  }
  &__link {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    &_brown {
      svg {
        path {
          fill: $color_brown;
        }
      }
    }
    svg:first-child {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    svg:last-child {
      circle {
        cursor: pointer;
        stroke-dasharray: 185px;
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 600ms ease;
        transform: rotate(90deg);
        transform-origin: 30px 30px;
      }
    }
    &:hover {
      svg:last-child {
        circle {
          stroke-dashoffset: 185px;
        }
      }
    }
    // &:hover {
    //   color: #0eb7da;
    // }
    // &::before,
    // &::after {
    //   content: "";
    //   box-sizing: inherit;
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   border-radius: 100%;
    //   top: 0;
    //   left: 0;
    // }
    // &::after {
    //   border: 0 solid $color_dark_grey;
    // }
    // &::before {
    //   border: 1px solid $color_dark_grey;
    // }
    // &:hover::after {
    //   border-top: 1px solid transparent;
    //   border-left-width: 1px;
    //   border-right-width: 1px;
    //   transform: rotate(270deg);
    //   transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s;
    // }
    // &:hover::before {
    //   border-top-color: transparent;
    //   border-right-color: transparent;
    //   border-bottom-color: transparent;
    //   transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s,
    //     border-bottom-color 0.15s linear 0.2s;
    // }
  }
}
