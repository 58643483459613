@import './footer';
@import '../templates/popups.scss';
@import '../templates/select2.scss';
@import '../templates/res-message.scss';
@import './animations.scss';

//header mobile

.aroma-header {
  // login
  &__user-online {
    position: absolute;
    top: 45px;
    left: -5px;
    z-index: 1;
    box-shadow: 0px 15px 50px 0px #00000026;
    border-top: 1px solid $color_dark_grey_2;
    transition-delay: 0.2s;

    width: 235px;
    padding: 24px;

    &::after {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      width: 40px;
      height: 22px;
      background: transparent;
      z-index: -1;
    }
  }
  &__user-some-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    ul {
      display: flex;
      flex-direction: column;
      gap: 12px;

      padding-bottom: 12px;
      border-bottom: 1px solid $color_grey_light;

      li {
        text-align: left;
        a {
          @include h6;
          display: inline-block;
          text-transform: uppercase;
          letter-spacing: 0.03em;
          color: $color_dark_grey_2;

          &:hover {
            color: $color_dark_grey;
          }
        }

        &:nth-child(3) {
          width: 188px;
        }
      }
    }
  }
  @media (max-width: $tablet-max) {
    &__mn-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      opacity: 70%;
      background: $color_dark_grey_2;
    }
    &__phone-and-lang-changer {
      align-items: center;
      padding: 27px 20px;

      .wishlist-and-cross {
        display: flex;
        gap: 16px;

        .wishlist,
        .cross {
          width: 18px;
          height: 18px;
        }

        .wishlist-btn, .cross-btn {
          display: block;
          width: 100%;
          height: 100%;
        }

        @media (max-width: $phone-max) {
          display: none;
        }
      }

      .aroma-header__phone-icon {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 0;
        height: 24px;
        margin-right: 22px;
        @media (max-width: $phone-max) {
          margin-right: 0;
        }

        a {
          @include custom-font-inter(700, 14px, 18px);
          letter-spacing: 0.03em;
        }

        .aroma-header__btn-img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          @media (min-width: $tablet_min) {
            display: none;
          }
        }
      }
      .aroma-header__language-change {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 18px;
        margin-right: auto;

        a, span {
          display: none;
          @include custom-font-inter(700, 14px, 18px);
          letter-spacing: 0.03em;
          text-transform: uppercase;
          color: $color_tide;
          order: 1;

          &.show {
            display: block;
          }

          &:active {
            color: $color_dark_grey_2;
          }

          &.active {
            color: $color_dark_grey_2;
            order: 0;

            &::after {
              position: absolute;
              top: 50%;
              right: -10px;
              display: block;
              content: "";
              width: 6px;
              height: 3px;
              background: url("../../images/icons/arrow_s.svg") no-repeat center;
            }
          }

          &:first-child {
            position: relative;
          }

          &:last-child {
            bottom: 0;
            position: relative;
            margin-left: 0;
          }
        }
      }
    }
    &__user-online {
      top: 52px;
    }
  }
  @media (max-width: $phone-max) {
    &__phone-and-lang-changer {
      justify-content: space-between;
      padding: 22px 20px;

      .aroma-header__phone-icon {
        // position: relative;
        // display: flex;
        // align-items: center;
        // padding-right: 0;
        // height: 24px;
        // padding-left: 28px;
        // a:first-child {
        //   position: absolute;
        //   display: block;
        //   content: "";
        //   top: 0;
        //   left: 0;
        //   width: 24px;
        //   height: 24px;
        //   background: url("../../images/icons/phone_header.svg") center no-repeat;
        // }

        // a {
        //   font-size: 0;
        // }
      }

      .aroma-header__language-change {
        flex-direction: row;
        gap: 12px;
        margin-right: 0;
        padding-left: 13px;

        &::before {
          position: absolute;
          content: "";
          top: -4px;
          left: 0;
          height: 26px;
          width: 1px;
          background-color: $color_grey_light;
        }

        a {
          display: block;
        }

        a,
        span.active::after {
          content: none;
        }
      }
    }
    &__user-online {
      top: 40px;
    }
  }
}

// Loader
// <span class="loader"></span>
.loader,
.loader-del {
  width: 40px;
  height: 40px;
  border: 5px solid $color_grey_2;
  border-bottom-color: $color_dark_grey_2;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  @media (max-width: $phone_max) {
    width: 25px;
    height: 25px;
  }
}

.loader_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: start;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

.loader_2 {
  position: sticky;
  top: 300px;
  width: 35px;
  aspect-ratio: 1;
  --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000);
  background:
    var(--_g) 0    0,
    var(--_g) 0    100%,
    var(--_g) 100% 100%;
  background-size: 40% 40%;
  animation: diagonalBackgroundShift 1s infinite linear;

  @media (max-width: $tablet-max) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// Scroll top btn
.scroll-top-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $color_black;
  &__arrow {
    display: flex;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background: url("../../images/icons/arrow_top_black.svg") center center
        no-repeat,
      $color_white_smoke;
    transition: all 0.3s ease-in;
  }
  &:hover {
    color: $color_black;
    .scroll-top-btn__arrow {
      background: url("../../images/icons/arrow_top_black.svg") center center no-repeat,
        $color_bg_grey;
    }
  }
}

.small-product-item-info {
  display: flex;
  align-items: center;
  width: 100%;
  background: $color_bg_grey;
  padding: 8px;
  border-radius: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
  &__title {
    font-weight: 400;
    margin-bottom: 10px;
  }
  &__content {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &__txt-holder {
    @include custom-font-inter(300, 16px, 23px);
    @media (max-width: $phone_max) {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  &__img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color_white;
    overflow: hidden;
    flex: 0 0 auto;
    width: 65px;
    height: 65px;
    img {
      object-fit: contain;
    }
    @media (max-width: $phone_max) {
      grid-column: 1/2;
      grid-row: 1/2;
    }
    @media (max-width: $phone_max) {
      width: 58px;
      height: 58px;
      align-self: flex-start;
    }
  }
  &__brand {
    @include custom-font-inter(700, 14px, 18px);
    text-transform: uppercase;
    color: $color_dark_grey_2;
    padding-right: 15px;
    margin-right: 15px;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      height: 30px;
      transform: translateY(-50%);
      top: 50%;
      right: 0;
      background: $color_dark_grey;
    }
    @media (max-width: $phone_max) {
      padding-left: 0;
      padding-right: 0;
      margin-right: 0;
      margin-bottom: 10px;
      &:after {
        display: none;
      }
    }
  }
  &__desc {
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    @media (max-width: $phone_max) {
      grid-column: 2/3;
      grid-row: 1/2;
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__separator {
    @media (max-width: $phone_max) {
      display: none;
    }
  }
  &__amount {
    margin-left: auto;
    padding-right: 10px;
    padding-left: 15px;
    position: relative;
    font-weight: 400;
    flex: 0 0 auto;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      height: 30px;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
      background: $color_dark_grey;
    }
    @media (max-width: $phone_max) {
      grid-column: 2/3;
      grid-row: 2/3;
      margin-left: 0;
      padding-left: 0;
      margin-top: 18px;
      &:before {
        display: none;
      }
    }
  }
  @media (max-width: $phone_max) {
    display: grid;
    grid-template-columns: 78px auto;
    grid-template-rows: auto auto;
    border-radius: 35px;
    padding: 12px;
  }
}

// Modal

.modal-window {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color_popup_overlay;
  z-index: 20;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    visibility: visible;
    opacity: 1;
  }
  &__wrapper {
    width: 552px;
    height: 164px;
    background: $color_white;
  }

  &__top {
    position: relative;
    padding: 20px 18px;
    text-align: center;

    .aroma-ico-close_big {
      position: absolute;
      top: 22px;
      right: 22px;
      font-size: 16px;
      cursor: pointer;
    }
  }
  &__content {
    display: flex;
    justify-content: center;
    padding: 30px 33px;
    text-align: center;

    p {
      @include custom-font-inter(400, 18px, 21px);
    }
  }

  @media (max-width: $phone-max) {
    &__wrapper {
      width: 320px;
      height: 180px;
    }
    &__top {
      padding: 20px 18px;
    }
    &__content {
      align-items: center;
      padding: 30px;
    }
  }
}

.language-change__modal {
  display: none;
  position: fixed;
  top: 51px;
  right: 40px;
  z-index: 10;
  width: 234px;
  height: 135px;
  padding: 18px 24px;
  text-align: center;
  background: $color_dark_grey_2;

  &.show {
    display: block;
  }

  &-title {
    position: relative;
    display: inline-block;
    padding-left: 25px;
    @include custom-font-inter(700, 12px, 15px);
    color: $color_white;
    text-transform: uppercase;

    &::before {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 15px;
      height: 15px;
      background: url('../../images/icons/ukraine_flag.svg') no-repeat center;
    }
  }
  &-btn {
    @include flex-center;
    margin: 16px auto;
    padding: 6px 0;
    background: $color_white;

    span {
      @include custom-font-inter(700, 12px, 18px);
      text-transform: uppercase;
      letter-spacing: 0.03;
      color: $color_black;
    }
  }
  &-text {
    @include body__text;
    color: #aaaaaa;
    text-decoration: underline;
  }

  @media (max-width: $tablet-max) {
    top: 58px;
    right: 20px;
  }

  @media (max-width: $phone-max) {
    top: 47px;
    right: initial;
    left: 20px;
    width: 199px;
    height: 113px;
    padding: 20px;

    &-title {
      font-size: 10px;
      line-height: 12px;
      padding-left: 22px;

      &::before {
        width: 12px;
        height: 12px;
      }
    }

    &-btn {
      margin: 12px 0;
      padding: 3px 0;

      span {
        font-size: 10px;
      }
    }

    &-text {
      font-size: 12px;
    }
  }
}
