@import "../uikit/vars/vars";
@import "../uikit/mixins";

//POPUP
.aroma-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color_popup_overlay;
  z-index: 15;
  visibility: hidden;
  opacity: 0;
  justify-content: flex-end;
  transition: opacity 0.2s linear;
  overflow-y: auto;
  overflow-x: hidden;
  &__close {
    display: block;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    &:before {
      content: url("../../images/icons/close_black.svg");
      height: 24px;
      display: block;
    }
  }
  // Right side popup wrapper
  &__wrap {
    width: 100%;
    max-width: 536px;
    background: $color_white;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    right: -50%;
    position: relative;
    transition: right 0.3s linear;
    @media (max-width: $phone_max) {
      right: 0;
      transition: none;
    }
  }
  // Centered popup wrapper
  &__wrapper {
    width: 100%;
    max-width: 606px;
    height: max-content;
    background: $color_white;
    display: flex;
    flex-direction: column;
    // overflow-y: auto;
    position: relative;
    opacity: 0;
    transition: opacity 0.2s linear;
    margin: auto;
    @media (max-width: $phone_max) {
      max-width: 320px;
    }
    &.availability {
      padding-bottom: 48px;
      @media (max-width: $phone_max) {
        margin: 60px auto;
      }
    }
  }
  &__title {
    margin: 28px;
    @include flex-between;
    p {
      @include custom-font-inter(700, 14px, 18px);
      text-transform: uppercase;
    }
    &-center {
      display: grid;
      grid-template-columns: auto 24px;
      justify-items: center;
      align-items: center;
      padding: 18px 20px;
      @media (max-width: $phone_max) {
        padding: 15px;
      }
      p {
        @include custom-font-inter(700, 14px, 18px);
        text-transform: uppercase;
      }
      .aroma-popup__close {
        margin-left: auto;
      }
    }
  }
  &__list {
    max-height: calc(100vh - 417px);
    position: relative;
    margin-right: 5px;
    @media (max-width: $phone_max) {
      margin-right: 0;
      max-height: calc(100vh - 377px);
    }
    &-item {
      position: relative;
      padding: 28px 48px;
      display: grid;
      grid-template-columns: 120px minmax(auto, 1fr) auto;
      @media (max-width: $phone_max) {
        padding: 28px 20px;
        grid-template-columns: 100px minmax(auto, 1fr) auto;
      }
      &-img {
        img {
          width: 120px;
          height: 120px;
          object-fit: contain;
          @media (max-width: $phone_max) {
            width: 100px;
            height: 100px;
          }
        }
      }
      &-info {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        a {
          display: block;
        }
      }
      &-title {
        @include custom-font-inter(700, 14px, 18px);
        text-transform: uppercase;
        margin-bottom: 6px;
      }
      &-subtitle,
      &-volume {
        @include custom-font-inter(400, 12px, normal);
      }
      &-quantity {
        @include custom-font-inter(600, 12px, normal);
        margin: 45px 0 2px;
      }
      &-price {
        margin-top: auto;
        &-old {
          display: block;
          @include custom-font-inter(400, 12px, normal);
          color: $color_grey_2;
          text-decoration: line-through;
          width: max-content;
          margin-left: auto;
        }
        &-new {
          display: block;
          @include custom-font-inter(300, 16px, 23px);
        }
      }
      &-btns {
        position: absolute;
        top: 28px;
        right: 48px;
        display: flex;
        align-items: center;
        gap: 16px;

        @media (max-width: $phone_max) {
          right: 20px;
        }
      }
    }
    &.ps {
      .ps__rail-y {
        width: 3px;
        background: $color_white;
        opacity: 1;
      }
      .ps__thumb-y {
        width: 3px;
        background: $color_dark_grey_2;
      }
    }
    .basket__list {
      &-item {
        display: grid;
        grid-template-columns: 140px auto;
        grid-template-rows: auto auto;
        padding: 0 15px 30px;
        @media (max-width: $tablet_max) {
          padding-bottom: 25px;
        }
        @media (max-width: $phone_max) {
          grid-template-columns: 118px auto;
          padding-bottom: 0;
          &:not(:last-child) {
            margin-bottom: 25px;
          }
        }

        &-img {
          display: block;
          grid-row: 1 / 3;
          grid-column: 1 / 2;
          margin-right: 20px;
          @media (max-width: $phone_max) {
            grid-row: 1 / 2;
            margin-right: 10px;
          }
          img,
          source {
            width: 120px;
            height: 120px;
            object-fit: contain;
            @media (max-width: $phone_max) {
              width: 100px;
              height: 100px;
            }
          }
        }
        &-top {
          grid-row: 1 / 2;
          grid-column: 2 / 3;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          &-btns {
            @include flex-between;
            gap: 16px;
          }
        }
        &-bottom {
          grid-row: 2 / 3;
          grid-column: 2 / 3;
          @include flex-between;
          height: max-content;
          margin-top: 30px;
          gap: 20px;
          @media (max-width: $phone_max) {
            grid-column: 1 / 3;
          }
        }
        &-title {
          @include custom-font-inter(700, 14px, 18px);
          text-transform: uppercase;
        }
        &-subtitle {
          @include custom-font-inter(300, 18px, 28px);
          margin: 7px 0 5px;
          @media (max-width: $phone_max) {
            @include custom-font-inter(400, 12px, normal);
            margin: 6px 0 0;
          }
        }
        &-descr {
          @include custom-font-inter(400, 14px, normal);
          @media (max-width: $phone_max) {
            font-size: 12px;
          }
        }
        &-control {
          display: flex;
          align-items: end;
          gap: 8px;
          height: 42px;
          @media (max-width: $phone_max) {
            height: 36px;
            gap: 5px;
          }
        }
        &-fav-btn,
        &-del-btn {
          border: none;
          background-color: transparent;
          @include flex-center;
          padding: 0;
          cursor: pointer;
          span {
            @include custom-font-inter(400, 12px, normal);
            margin-left: 8px;
            @media (max-width: $tablet_max) {
              display: none;
            }
          }
        }
        &-fav-btn {
          &.active {
            svg {
              animation: heartbeat 0.8s ease;
            }
            svg path {
              fill: $color_dark_grey_2;
              stroke: $color_dark_grey_2;
            }
          }
        }

        .delete {
          &:active {
            path {
              stroke: #000;
            }
          }
        }

        &-volume {
          width: 160px;
          .select2-container--select2-volume .select2-selection--single {
            height: 42px;
          }
          @media (max-width: $phone_max) {
            .select2-container--select2-volume .select2-selection--single {
              height: 36px;
            }
          }
        }
        &-quantity {
          border: 1px solid $color_grey_light;
          padding: 16px 8px;
          @include flex-between;
          width: 90px;
          height: 100%;
          padding: 0 5px;
          @media (max-width: $phone_max) {
            width: 85px;
          }
          input {
            @include custom-font-inter(700, 14px, 18px);
            border-left: 1px solid $color_white_smoke;
            border-right: 1px solid $color_white_smoke;
            padding: 0 3px;
            width: 27px;
            text-align: center;
            user-select: none;
          }
          button {
            @include flex-center;
          }
          .minus[disabled],
          .plus[disabled] {
            path {
              stroke: $color_grey_2;
            }
          }
        }
        &-price {
          &-old {
            display: block;
            margin-left: auto;
            width: max-content;
            @include custom-font-inter(400, 12px, normal);
            color: $color_dark_grey;
            text-decoration: line-through;
            @media (max-width: $phone_max) {
              font-weight: 300;
            }
          }
          &-new {
            display: block;
            @include custom-font-inter(400, 16px, 23px);
            color: $color_dark_grey_2;
            @media (max-width: $phone_max) {
              font-weight: 300;
            }
          }
          .loader {
            display: none;
          }
        }
      }
    }
  }
  &__info {
    padding: 40px 48px;
    @media (max-width: $phone_max) {
      padding: 30px 20px;
      position: relative;
    }
    &-delivery,
    &-total {
      @include flex-between;
      @include custom-font-inter(700, 14px, 18px);
      text-transform: uppercase;
      &.promo-total {
        color: $color_grey_2;
      }
      .loader {
        display: none;
      }
      &.loading {
        .total-price {
          display: none;
        }
        .loader {
          display: block;
          width: 18px;
          height: 18px;
        }
      }
    }
    &-delivery {
      padding-bottom: 20px;
      border-bottom: 1px solid $color_grey_light;
      margin-bottom: 20px;
      p:last-of-type {
        color: $color_red;
      }
    }
    // &-promo {
    //   margin: 40px 0 0;
    // }
    // .btn-white {
    //   margin-top: 30px;
    // }
    &-btns {
      @include flex-between;
      gap: 10px;
      margin-top: 20px;
      @media (max-width: $phone_max) {
        position: sticky;
        bottom: 29px;
      }
      .btn-white {
        width: 190px;
      }
      .btn-black {
        width: 240px;
      }
    }
    .mobile-hidden {
      @media (max-width: $phone_max) {
        display: none;
      }
    }
  }
  &__content {
    .buy-in-one-click__form {
      padding: 30px 56px 56px;
      @include flex-center;
      flex-direction: column;
      gap: 20px;
      @media (max-width: $phone_max) {
        padding: 30px;
      }
      p {
        @include custom-font-inter(400, 14px, normal);
        text-align: center;
      }
      .input-grey {
        margin-bottom: 0;
      }
      fieldset.error {
        input {
          border: 1px solid $color_red;
        }
        &:after {
          content: "Невірний формат номеру";
          color: $color_red;
          margin-top: 5px;
          @include custom-font-inter(300, 12px, 14px);
        }
      }
    }
    &_add-to-aromateka {
      .opinion {
        border: 1px solid $color_grey_3;
        textarea {
          width: 100%;
          border: 0;
          background: transparent;
          resize: none;
          padding: 15px;
        }
        legend {
          font-weight: 400;
          margin-left: 20px;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
      .aromateka-describer {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        .checkbox-container * {
          font-size: 12px;
          text-transform: none;
        }
        &__column {
          width: 25%;
          flex: 0 0 auto;
          flex-wrap: wrap;
          @media all and (max-width: $phone_max) {
            width: 50%;
          }
          ul {
            padding-right: 20px;
          }
          &_fw {
            width: 100%;
            @media all and (max-width: $phone_max) {
              width: 100%;
            }
            ul {
              display: flex;
              flex-wrap: wrap;
              padding-right: 0;

              li {
                width: 25%;
                padding-right: 20px;
                @media all and (max-width: $phone_max) {
                  width: 50%;
                }
              }
            }
          }
        }
        &__title {
          text-transform: uppercase;
          margin-bottom: 10px;
          display: inline-flex;
        }
        ul {
          margin-bottom: 20px;
          li {
            list-style: none;
            margin-bottom: 7px;
          }
        }
      }
      .btn-submit {
        margin-top: 60px;
      }
    }

    .contact-data-in-popup {
      border-top: 1px solid $color_grey_3;
      border-bottom: 1px solid $color_grey_3;
      margin-top: 90px;
      padding-bottom: 40px;
      @media all and (max-width: $phone_max) {
        margin-top: 40px;
      }
      &__wrapper {
        max-width: 325px;
        margin-left: auto;
        margin-right: auto;
      }
      &__title {
        text-align: center;
        padding-top: 40px;
        font-size: 18px;
        margin-bottom: 30px;
      }
      fieldset {
        margin-bottom: 20px;
      }
      .btn {
        width: 100%;
      }
    }
    .recomendation-in-popup {
      margin-top: 40px;
      &__title {
        margin-bottom: 15px;
        @media all and (max-width: $phone_max) {
          text-align: center;
        }
      }
      .small-product-item-info {
        background: $color_grey_3;
      }
    }
    .ask-consultant-in-popup {
      display: flex;
      margin-top: 60px;
      flex-wrap: wrap;
      @media all and (max-width: $phone_max) {
        flex-direction: column-reverse;
      }
      &__column {
        width: 50%;
        @media all and (max-width: $phone_max) {
          width: 100%;
          margin-bottom: 20px;
        }
        &_right {
          display: flex;
          flex-direction: column;
          align-items: center;
          a {
            display: inline-flex;
            text-transform: uppercase;
            margin-bottom: 20px;
          }
        }
      }
      fieldset {
        margin-bottom: 20px;
      }
      .btn {
        width: 100%;
      }
      &__write-in-chat {
        padding-left: 25px;
        position: relative;
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 18px;
          height: 15px;
          background: url("../../images/icons/telegram-small.svg") center
            no-repeat;
          background-size: contain;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &_viber {
          &:before {
            background: url("../../images/icons/viber-small.svg") center
              no-repeat;
            background-size: contain;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
    .select-samples-block {
      padding-top: 12px;
      padding-bottom: 12px;
      position: relative;
      font-weight: 400;
      text-align: center;
      a {
        color: $color_brown;
      }
      &:before {
        content: "";
        height: 1px;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: $color_grey_3;
      }
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        display: block;
        bottom: 0;
        left: 0;
        background: $color_grey_3;
      }
    }
  }
  &.active {
    display: flex;
    visibility: visible;
    opacity: 1;
    .aroma-popup__wrap {
      right: 0;
    }
    .aroma-popup__empty-cart {
      margin: auto;
      .empty-basket__subtitle {
        @include custom-font-inter(300, 18px, 28px);
        margin: 32px 0 20px;
        @media (max-width: $phone_max) {
          margin: 16px 0 5px;
        }
      }
      .empty-basket__text {
        @include custom-font-inter(400, 12px, normal);
        margin-bottom: 10px;
      }
      .link {
        @include custom-font-inter(400, 12px, normal);
        text-transform: none;
        &::before {
          bottom: 0;
        }
      }

      @media (max-width: $phone-max) {
        padding: 0 20px;
        text-align: center;
      }
    }
    .aroma-popup__wrapper {
      opacity: 1;
    }
  }

  .availability {
    &__title {
      text-align: center;
      @include custom-font-inter(700, 14px, 18px);
      text-transform: uppercase;
      padding: 0 45px;
      @media all and (max-width: $phone_max) {
        text-align: left;
        padding: 0 20px;
      }
    }
    &__subtitle {
      @include custom-font-inter(400, 14px, normal);
      margin: 30px 0;
      padding: 0 48px;
      @media all and (max-width: $phone_max) {
        padding: 0 20px;
      }
      span:first-child {
        font-weight: 700;
      }

      // span:last-child {
      //   font-weight: 300;
      //   border-bottom: 1px solid $color_black;
      // }
    }
    &__stores {
      padding: 0 47px;
      @include custom-font-inter(700, 14px, normal);
      @media all and (max-width: $phone_max) {
        padding: 0 20px;
      }
      &-title {
        margin-top: 30px;
      }
      &-list {
        font-weight: 400;
        list-style-type: disc;
        padding-left: 23px;
      }
      &-item {
        margin-top: 16px;
      }
    }
    a {
      font-weight: 300;
      width: max-content;
      display: block;
      &:before {
        bottom: -2px;
      }
    }
  }
  @media (max-width: $tablet_max) and (orientation: landscape) and (max-height: 600px) {
    .aroma-popup__list {
      height: auto;
    }
  }
}

// Auth form
.auth-form {
  .aroma-popup__wrapper {
    max-width: 552px;
    @media (max-width: $phone_max) {
      margin: unset;
      margin-bottom: 100px;
    }
  }
  &__wrapper {
    padding: 0 56px 56px 56px;
    text-align: center;
    @media (max-width: $phone_max) {
      padding: 0 20px 30px 20px;
    }
    .btn-black {
      margin-top: 20px;
    }

    .input-grey {
      &.code-error {
        input {
          border: 1px solid $color_red;
        }
        &:after {
          display: block;
          content: "Код має складатися з 4-х символів";
        }
      }
      &.code-error-ru {
        input {
          border: 1px solid $color_red;
        }
        &:after {
          display: block;
          content: "Код должен состоять из 4-х символов";
        }
      }
    }

    .code-input {
      position: relative;
      .toggle-pass-visible {
        display: block;
        &:after {
          display: none;
          content: url("../../images/icons/eye.svg");
          position: absolute;
          top: 50%;
          right: 18px;
          transform: translateY(-50%);
          height: 14px;
        }
      }
    }
    input[type="password"] {
      letter-spacing: 5px;
    }
    .loader,
    .btn-after-submit {
      display: none;
    }
    .btn-black.loading {
      .loader {
        display: block;
      }
      .string,
      .btn-after-submit {
        display: none;
      }
    }
    .error-message,
    .register-error-message {
      display: none;
      margin-top: 20px;
      @include custom-font-inter(300, 14px, 18px);
      color: $color_red;

      &.show {
        display: inline-block;
      }
    }
  }
  &__tabs-control {
    display: flex;
    padding: 0 56px;
    margin-bottom: 30px;
    @media (max-width: $phone_max) {
      padding: 0;
    }
    button {
      position: relative;
      @include flex-center;
      @include custom-font-inter(700, 14px, 18px);
      text-transform: uppercase;
      padding: 24px 0;
      width: 50%;
      border-bottom: 1px solid $color_grey_light;
      color: $color_grey_2;
      &.active {
        color: $color_dark_grey_2;
        border-color: $color_dark_grey_2;
      }
      &:first-of-type::after {
        content: "";
        width: 1px;
        height: 25px;
        background-color: $color_grey_light;
        position: absolute;
        right: 0;
      }
    }
    button:first-of-type {
      @media (max-width: $phone_max) {
        width: 40%;
      }
    }
    button:last-of-type {
      @media (max-width: $phone_max) {
        width: 60%;
      }
    }
  }
  &__message {
    margin-top: -10px;
    margin-bottom: 20px;
    font-weight: normal;
  }
  form.after-submit {
    .btn-black {
      .string {
        display: none;
      }
      .btn-after-submit {
        display: block;
      }
    }
    .btn-black.loading {
      .loader {
        display: block;
      }
      .string,
      .btn-after-submit {
        display: none;
      }
    }
  }
  .login-form {
    .code-input,
    .login-form__resend-block {
      display: none;
    }
    &.after-submit {
      .code-input,
      .login-form__resend-block {
        display: block;
      }
    }
    .login-form__resend-block {
      margin-top: 20px;
      text-align: center;
      @media (max-width: $phone_max) {
        margin-top: 30px;
      }
      p {
        @include custom-font-inter(300, 12px, 14px);
        margin-bottom: 10px;
        @media (max-width: $phone_max) {
          margin-bottom: 20px;
        }
      }
    }
  }
  .register-form {
    &__code-block {
      display: none;
      text-align: center;
      margin-top: 30px;
      p {
        @include custom-font-inter(300, 12px, 14px);
        margin-bottom: 20px;
      }
      .checkbox-default {
        @include custom-font-inter(300, 12px, 14px);
        width: fit-content;
        &:first-of-type {
          margin: 25px 0 10px;
        }
        &:last-of-type {
          margin-bottom: 20px;
          @media (max-width: $phone_max) {
            margin-bottom: 35px;
          }
        }
      }
    }
    &__resend-block {
      display: none;
      margin-top: 20px;
      text-align: center;
      @media (max-width: $phone_max) {
        margin-top: 30px;
      }
      p {
        @include custom-font-inter(300, 12px, 14px);
        margin-bottom: 10px;
        @media (max-width: $phone_max) {
          margin-bottom: 20px;
        }
      }
    }
    &.after-submit {
      .register-form__code-block {
        display: block;
      }
      .register-form__resend-block {
        display: block;
      }
    }
  }
  .input-grey input {
    @media (max-width: $phone_max) {
      height: 60px;
    }
  }
  .btn-black {
    @media (max-width: $phone_max) {
      height: 60px;
    }
  }
}

@media (max-width: $phone_max) {
  .aroma-popup.auth-form {
    top: 57px;
  }
}
// Promocode block on cart-popup
.promocode-form {
  margin-top: 40px;
  margin-bottom: 20px;
  &.loading {
    .loader {
      display: inline-block;
    }
    button {
      display: none;
    }
  }
  .input-grey {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $color_dark_grey_2;
    input {
      border: none;
      &:valid {
        +button {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    legend {
      color: $color_dark_grey_2;
    }
    button {
      opacity: 0;
      visibility: hidden;
      transition: opacity .2s;
      height: min-content;
      padding-right: 10px;
      font-family: $inter;
      font-weight: 700;
      color: $color_green_2;
    }
  }
  .loader {
    display: none;
    width: 33px;
    height: 30px;
    margin-right: 15px;
  }
  .promocode-block {
    display: none;
    &.visible {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &-applied {
      button {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .promocode-applied-code {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 22px 18px 22px 30px;
    @media (max-width: $phone_max) {
      height: 50px;
    }
  }
}

.giftcard-form {
    margin-top: 40px;
    margin-bottom: 20px;
    &.loading {
        .loader {
            display: inline-block;
        }
        button {
            display: none;
        }
    }
    .input-grey {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $color_dark_grey_2;
        input {
            border: none;
            &:valid {
                +button {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        legend {
            color: $color_dark_grey_2;
        }
        button {
            opacity: 0;
            visibility: hidden;
            transition: opacity .2s;
            height: min-content;
            padding-right: 10px;
            font-family: $inter;
            font-weight: 700;
            color: $color_green_2;
        }
    }
    .loader {
        display: none;
        width: 33px;
        height: 30px;
        margin-right: 15px;
    }
    .giftcard-block {
        display: none;
        &.visible {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        &-applied {
            button {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .giftcard-applied-code {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 22px 18px 22px 30px;
        @media (max-width: $phone_max) {
            height: 50px;
        }
    }
}

.cart-popup {
  .aroma-popup__info {
    .btn-white {
      @media (max-width: $phone_max) {
        position: sticky;
        bottom: 98px;
      }
    }
  }
}
