@charset "UTF-8";

/* Sizes */

/* Sizes */

/* Sizes */

.socials-list {
  display: flex;
  align-items: center;
}

.socials-list__item:not(:first-child) {
  margin-left: 8px;
}

.socials-list__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.socials-list__link_brown svg path {
  fill: #735e52;
}

.socials-list__link svg:first-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.socials-list__link svg:last-child circle {
  cursor: pointer;
  stroke-dasharray: 185px;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 600ms ease;
  transform: rotate(90deg);
  transform-origin: 30px 30px;
}

.socials-list__link:hover svg:last-child circle {
  stroke-dashoffset: 185px;
}

.aroma-footer {
  background: #f8f8f8;
  margin-top: 60px;
}

.aroma-footer__row {
  display: flex;
  justify-content: space-between;
}

.aroma-footer__column {
  position: relative;
  padding: 43px 30px 21px;
  border-left: 1px solid #ffffff;
}

.aroma-footer__column:first-child {
  padding-left: 0;
  border: 0;
}

.aroma-footer__column .line {
  display: block;
  position: absolute;
  top: 0;
  left: 284px;
  width: 1px;
  height: 194px;
  background-color: #ffffff;
}

.aroma-footer__links {
  display: flex;
  gap: 61px;
}

.aroma-footer__links-wrap {
  display: flex;
  align-items: baseline;
}

.aroma-footer__links-wrap span {
  color: #545454;
}

.aroma-footer__links-wrap ul {
  margin-left: 20px;
  text-transform: uppercase;
  max-width: 190px;
}

.aroma-footer__links-wrap ul li:not(:last-child) {
  margin-bottom: 8px;
}

.aroma-footer__links-wrap a {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #545454;
}

.aroma-footer__links-wrap a:hover {
  color: #aaaaaa;
}

.aroma-footer__copyright {
  padding: 20px 0;
  align-items: center;
  border-top: 1px solid #ffffff;
}

.aroma-footer__copyright a {
  position: relative;
}

.aroma-footer__copyright a:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 1px;
  width: 100%;
  background: #38373d;
}

.aroma-footer__copyright span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #aaaaaa;
  letter-spacing: 0.03em;
}

.aroma-footer__copyright .txt__small-addition {
  margin-right: 140px;
}

.aroma-footer__socials {
  padding: 45px 10px 0 50px;
}

.cities {
  width: 107px;
  margin-right: 10px;
}

/* Sizes */

/* Sizes */

.aroma-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #212121b3;
  z-index: 15;
  visibility: hidden;
  opacity: 0;
  justify-content: flex-end;
  transition: opacity 0.2s linear;
  overflow-y: auto;
  overflow-x: hidden;
}

.aroma-popup__close {
  display: block;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
}

.aroma-popup__close:before {
  content: url("../../images/icons/close_black.svg");
  height: 24px;
  display: block;
}

.aroma-popup__wrap {
  width: 100%;
  max-width: 536px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  right: -50%;
  position: relative;
  transition: right 0.3s linear;
}

.aroma-popup__wrapper {
  width: 100%;
  max-width: 606px;
  height: max-content;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: 0;
  transition: opacity 0.2s linear;
  margin: auto;
}

.aroma-popup__wrapper.availability {
  padding-bottom: 48px;
}

.aroma-popup__title {
  margin: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aroma-popup__title p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.aroma-popup__title-center {
  display: grid;
  grid-template-columns: auto 24px;
  justify-items: center;
  align-items: center;
  padding: 18px 20px;
}

.aroma-popup__title-center p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.aroma-popup__title-center .aroma-popup__close {
  margin-left: auto;
}

.aroma-popup__list {
  max-height: calc(100vh - 417px);
  position: relative;
  margin-right: 5px;
}

.aroma-popup__list-item {
  position: relative;
  padding: 28px 48px;
  display: grid;
  grid-template-columns: 120px minmax(auto, 1fr) auto;
}

.aroma-popup__list-item-img img {
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.aroma-popup__list-item-info {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.aroma-popup__list-item-info a {
  display: block;
}

.aroma-popup__list-item-title {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.aroma-popup__list-item-subtitle,
.aroma-popup__list-item-volume {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
}

.aroma-popup__list-item-quantity {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: normal;
  margin: 45px 0 2px;
}

.aroma-popup__list-item-price {
  margin-top: auto;
}

.aroma-popup__list-item-price-old {
  display: block;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: #aaaaaa;
  text-decoration: line-through;
  width: max-content;
  margin-left: auto;
}

.aroma-popup__list-item-price-new {
  display: block;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
}

.aroma-popup__list-item-btns {
  position: absolute;
  top: 28px;
  right: 48px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.aroma-popup__list.ps .ps__rail-y {
  width: 3px;
  background: #ffffff;
  opacity: 1;
}

.aroma-popup__list.ps .ps__thumb-y {
  width: 3px;
  background: #212121;
}

.aroma-popup__list .basket__list-item {
  display: grid;
  grid-template-columns: 140px auto;
  grid-template-rows: auto auto;
  padding: 0 15px 30px;
}

.aroma-popup__list .basket__list-item-img {
  display: block;
  grid-row: 1 / 3;
  grid-column: 1 / 2;
  margin-right: 20px;
}

.aroma-popup__list .basket__list-item-img img,
.aroma-popup__list .basket__list-item-img source {
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.aroma-popup__list .basket__list-item-top {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.aroma-popup__list .basket__list-item-top-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.aroma-popup__list .basket__list-item-bottom {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: max-content;
  margin-top: 30px;
  gap: 20px;
}

.aroma-popup__list .basket__list-item-title {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.aroma-popup__list .basket__list-item-subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  margin: 7px 0 5px;
}

.aroma-popup__list .basket__list-item-descr {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
}

.aroma-popup__list .basket__list-item-control {
  display: flex;
  align-items: end;
  gap: 8px;
  height: 42px;
}

.aroma-popup__list .basket__list-item-fav-btn,
.aroma-popup__list .basket__list-item-del-btn {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
}

.aroma-popup__list .basket__list-item-fav-btn span,
.aroma-popup__list .basket__list-item-del-btn span {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  margin-left: 8px;
}

.aroma-popup__list .basket__list-item-fav-btn.active svg {
  animation: heartbeat 0.8s ease;
}

.aroma-popup__list .basket__list-item-fav-btn.active svg path {
  fill: #212121;
  stroke: #212121;
}

.aroma-popup__list .basket__list-item .delete:active path {
  stroke: #000;
}

.aroma-popup__list .basket__list-item-volume {
  width: 160px;
}

.aroma-popup__list .basket__list-item-volume .select2-container--select2-volume .select2-selection--single {
  height: 42px;
}

.aroma-popup__list .basket__list-item-quantity {
  border: 1px solid #dadada;
  padding: 16px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px;
  height: 100%;
  padding: 0 5px;
}

.aroma-popup__list .basket__list-item-quantity input {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  border-left: 1px solid #f8f8f8;
  border-right: 1px solid #f8f8f8;
  padding: 0 3px;
  width: 27px;
  text-align: center;
  user-select: none;
}

.aroma-popup__list .basket__list-item-quantity button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aroma-popup__list .basket__list-item-quantity .minus[disabled] path,
.aroma-popup__list .basket__list-item-quantity .plus[disabled] path {
  stroke: #aaaaaa;
}

.aroma-popup__list .basket__list-item-price-old {
  display: block;
  margin-left: auto;
  width: max-content;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: #c8c8c8;
  text-decoration: line-through;
}

.aroma-popup__list .basket__list-item-price-new {
  display: block;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #212121;
}

.aroma-popup__list .basket__list-item-price .loader {
  display: none;
}

.aroma-popup__info {
  padding: 40px 48px;
}

.aroma-popup__info-delivery,
.aroma-popup__info-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.aroma-popup__info-delivery.promo-total,
.aroma-popup__info-total.promo-total {
  color: #aaaaaa;
}

.aroma-popup__info-delivery .loader,
.aroma-popup__info-total .loader {
  display: none;
}

.aroma-popup__info-delivery.loading .total-price,
.aroma-popup__info-total.loading .total-price {
  display: none;
}

.aroma-popup__info-delivery.loading .loader,
.aroma-popup__info-total.loading .loader {
  display: block;
  width: 18px;
  height: 18px;
}

.aroma-popup__info-delivery {
  padding-bottom: 20px;
  border-bottom: 1px solid #dadada;
  margin-bottom: 20px;
}

.aroma-popup__info-delivery p:last-of-type {
  color: #ff0000;
}

.aroma-popup__info-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.aroma-popup__info-btns .btn-white {
  width: 190px;
}

.aroma-popup__info-btns .btn-black {
  width: 240px;
}

.aroma-popup__content .buy-in-one-click__form {
  padding: 30px 56px 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.aroma-popup__content .buy-in-one-click__form p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  text-align: center;
}

.aroma-popup__content .buy-in-one-click__form .input-grey {
  margin-bottom: 0;
}

.aroma-popup__content .buy-in-one-click__form fieldset.error input {
  border: 1px solid #ff0000;
}

.aroma-popup__content .buy-in-one-click__form fieldset.error:after {
  content: "Невірний формат номеру";
  color: #ff0000;
  margin-top: 5px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}

.aroma-popup__content_add-to-aromateka .opinion {
  border: 1px solid #e6e4de;
}

.aroma-popup__content_add-to-aromateka .opinion textarea {
  width: 100%;
  border: 0;
  background: transparent;
  resize: none;
  padding: 15px;
}

.aroma-popup__content_add-to-aromateka .opinion legend {
  font-weight: 400;
  margin-left: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.aroma-popup__content_add-to-aromateka .aromateka-describer {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
}

.aroma-popup__content_add-to-aromateka .aromateka-describer .checkbox-container * {
  font-size: 12px;
  text-transform: none;
}

.aroma-popup__content_add-to-aromateka .aromateka-describer__column {
  width: 25%;
  flex: 0 0 auto;
  flex-wrap: wrap;
}

.aroma-popup__content_add-to-aromateka .aromateka-describer__column ul {
  padding-right: 20px;
}

.aroma-popup__content_add-to-aromateka .aromateka-describer__column_fw {
  width: 100%;
}

.aroma-popup__content_add-to-aromateka .aromateka-describer__column_fw ul {
  display: flex;
  flex-wrap: wrap;
  padding-right: 0;
}

.aroma-popup__content_add-to-aromateka .aromateka-describer__column_fw ul li {
  width: 25%;
  padding-right: 20px;
}

.aroma-popup__content_add-to-aromateka .aromateka-describer__title {
  text-transform: uppercase;
  margin-bottom: 10px;
  display: inline-flex;
}

.aroma-popup__content_add-to-aromateka .aromateka-describer ul {
  margin-bottom: 20px;
}

.aroma-popup__content_add-to-aromateka .aromateka-describer ul li {
  list-style: none;
  margin-bottom: 7px;
}

.aroma-popup__content_add-to-aromateka .btn-submit {
  margin-top: 60px;
}

.aroma-popup__content .contact-data-in-popup {
  border-top: 1px solid #e6e4de;
  border-bottom: 1px solid #e6e4de;
  margin-top: 90px;
  padding-bottom: 40px;
}

.aroma-popup__content .contact-data-in-popup__wrapper {
  max-width: 325px;
  margin-left: auto;
  margin-right: auto;
}

.aroma-popup__content .contact-data-in-popup__title {
  text-align: center;
  padding-top: 40px;
  font-size: 18px;
  margin-bottom: 30px;
}

.aroma-popup__content .contact-data-in-popup fieldset {
  margin-bottom: 20px;
}

.aroma-popup__content .contact-data-in-popup .btn {
  width: 100%;
}

.aroma-popup__content .recomendation-in-popup {
  margin-top: 40px;
}

.aroma-popup__content .recomendation-in-popup__title {
  margin-bottom: 15px;
}

.aroma-popup__content .recomendation-in-popup .small-product-item-info {
  background: #e6e4de;
}

.aroma-popup__content .ask-consultant-in-popup {
  display: flex;
  margin-top: 60px;
  flex-wrap: wrap;
}

.aroma-popup__content .ask-consultant-in-popup__column {
  width: 50%;
}

.aroma-popup__content .ask-consultant-in-popup__column_right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aroma-popup__content .ask-consultant-in-popup__column_right a {
  display: inline-flex;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.aroma-popup__content .ask-consultant-in-popup fieldset {
  margin-bottom: 20px;
}

.aroma-popup__content .ask-consultant-in-popup .btn {
  width: 100%;
}

.aroma-popup__content .ask-consultant-in-popup__write-in-chat {
  padding-left: 25px;
  position: relative;
}

.aroma-popup__content .ask-consultant-in-popup__write-in-chat:before {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  height: 15px;
  background: url("../../images/icons/telegram-small.svg") center no-repeat;
  background-size: contain;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.aroma-popup__content .ask-consultant-in-popup__write-in-chat_viber:before {
  background: url("../../images/icons/viber-small.svg") center no-repeat;
  background-size: contain;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.aroma-popup__content .select-samples-block {
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  font-weight: 400;
  text-align: center;
}

.aroma-popup__content .select-samples-block a {
  color: #735e52;
}

.aroma-popup__content .select-samples-block:before {
  content: "";
  height: 1px;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #e6e4de;
}

.aroma-popup__content .select-samples-block:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  display: block;
  bottom: 0;
  left: 0;
  background: #e6e4de;
}

.aroma-popup.active {
  display: flex;
  visibility: visible;
  opacity: 1;
}

.aroma-popup.active .aroma-popup__wrap {
  right: 0;
}

.aroma-popup.active .aroma-popup__empty-cart {
  margin: auto;
}

.aroma-popup.active .aroma-popup__empty-cart .empty-basket__subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  margin: 32px 0 20px;
}

.aroma-popup.active .aroma-popup__empty-cart .empty-basket__text {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  margin-bottom: 10px;
}

.aroma-popup.active .aroma-popup__empty-cart .link {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  text-transform: none;
}

.aroma-popup.active .aroma-popup__empty-cart .link::before {
  bottom: 0;
}

.aroma-popup.active .aroma-popup__wrapper {
  opacity: 1;
}

.aroma-popup .availability__title {
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 0 45px;
}

.aroma-popup .availability__subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  margin: 30px 0;
  padding: 0 48px;
}

.aroma-popup .availability__subtitle span:first-child {
  font-weight: 700;
}

.aroma-popup .availability__stores {
  padding: 0 47px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
}

.aroma-popup .availability__stores-title {
  margin-top: 30px;
}

.aroma-popup .availability__stores-list {
  font-weight: 400;
  list-style-type: disc;
  padding-left: 23px;
}

.aroma-popup .availability__stores-item {
  margin-top: 16px;
}

.aroma-popup .availability a {
  font-weight: 300;
  width: max-content;
  display: block;
}

.aroma-popup .availability a:before {
  bottom: -2px;
}

.auth-form .aroma-popup__wrapper {
  max-width: 552px;
}

.auth-form__wrapper {
  padding: 0 56px 56px 56px;
  text-align: center;
}

.auth-form__wrapper .btn-black {
  margin-top: 20px;
}

.auth-form__wrapper .input-grey.code-error input {
  border: 1px solid #ff0000;
}

.auth-form__wrapper .input-grey.code-error:after {
  display: block;
  content: "Код має складатися з 4-х символів";
}

.auth-form__wrapper .input-grey.code-error-ru input {
  border: 1px solid #ff0000;
}

.auth-form__wrapper .input-grey.code-error-ru:after {
  display: block;
  content: "Код должен состоять из 4-х символов";
}

.auth-form__wrapper .code-input {
  position: relative;
}

.auth-form__wrapper .code-input .toggle-pass-visible {
  display: block;
}

.auth-form__wrapper .code-input .toggle-pass-visible:after {
  display: none;
  content: url("../../images/icons/eye.svg");
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  height: 14px;
}

.auth-form__wrapper input[type="password"] {
  letter-spacing: 5px;
}

.auth-form__wrapper .loader,
.auth-form__wrapper .btn-after-submit {
  display: none;
}

.auth-form__wrapper .btn-black.loading .loader {
  display: block;
}

.auth-form__wrapper .btn-black.loading .string,
.auth-form__wrapper .btn-black.loading .btn-after-submit {
  display: none;
}

.auth-form__wrapper .error-message,
.auth-form__wrapper .register-error-message {
  display: none;
  margin-top: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #ff0000;
}

.auth-form__wrapper .error-message.show,
.auth-form__wrapper .register-error-message.show {
  display: inline-block;
}

.auth-form__tabs-control {
  display: flex;
  padding: 0 56px;
  margin-bottom: 30px;
}

.auth-form__tabs-control button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 24px 0;
  width: 50%;
  border-bottom: 1px solid #dadada;
  color: #aaaaaa;
}

.auth-form__tabs-control button.active {
  color: #212121;
  border-color: #212121;
}

.auth-form__tabs-control button:first-of-type::after {
  content: "";
  width: 1px;
  height: 25px;
  background-color: #dadada;
  position: absolute;
  right: 0;
}

.auth-form__message {
  margin-top: -10px;
  margin-bottom: 20px;
  font-weight: normal;
}

.auth-form form.after-submit .btn-black .string {
  display: none;
}

.auth-form form.after-submit .btn-black .btn-after-submit {
  display: block;
}

.auth-form form.after-submit .btn-black.loading .loader {
  display: block;
}

.auth-form form.after-submit .btn-black.loading .string,
.auth-form form.after-submit .btn-black.loading .btn-after-submit {
  display: none;
}

.auth-form .login-form .code-input,
.auth-form .login-form .login-form__resend-block {
  display: none;
}

.auth-form .login-form.after-submit .code-input,
.auth-form .login-form.after-submit .login-form__resend-block {
  display: block;
}

.auth-form .login-form .login-form__resend-block {
  margin-top: 20px;
  text-align: center;
}

.auth-form .login-form .login-form__resend-block p {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
}

.auth-form .register-form__code-block {
  display: none;
  text-align: center;
  margin-top: 30px;
}

.auth-form .register-form__code-block p {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 20px;
}

.auth-form .register-form__code-block .checkbox-default {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  width: fit-content;
}

.auth-form .register-form__code-block .checkbox-default:first-of-type {
  margin: 25px 0 10px;
}

.auth-form .register-form__code-block .checkbox-default:last-of-type {
  margin-bottom: 20px;
}

.auth-form .register-form__resend-block {
  display: none;
  margin-top: 20px;
  text-align: center;
}

.auth-form .register-form__resend-block p {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
}

.auth-form .register-form.after-submit .register-form__code-block {
  display: block;
}

.auth-form .register-form.after-submit .register-form__resend-block {
  display: block;
}

.promocode-form {
  margin-top: 40px;
  margin-bottom: 20px;
}

.promocode-form.loading .loader {
  display: inline-block;
}

.promocode-form.loading button {
  display: none;
}

.promocode-form .input-grey {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #212121;
}

.promocode-form .input-grey input {
  border: none;
}

.promocode-form .input-grey input:valid + button {
  opacity: 1;
  visibility: visible;
}

.promocode-form .input-grey legend {
  color: #212121;
}

.promocode-form .input-grey button {
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s;
  height: min-content;
  padding-right: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: #45ab55;
}

.promocode-form .loader {
  display: none;
  width: 33px;
  height: 30px;
  margin-right: 15px;
}

.promocode-form .promocode-block {
  display: none;
}

.promocode-form .promocode-block.visible {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.promocode-form .promocode-block-applied button {
  opacity: 1;
  visibility: visible;
}

.promocode-form .promocode-applied-code {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 22px 18px 22px 30px;
}

.giftcard-form {
  margin-top: 40px;
  margin-bottom: 20px;
}

.giftcard-form.loading .loader {
  display: inline-block;
}

.giftcard-form.loading button {
  display: none;
}

.giftcard-form .input-grey {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #212121;
}

.giftcard-form .input-grey input {
  border: none;
}

.giftcard-form .input-grey input:valid + button {
  opacity: 1;
  visibility: visible;
}

.giftcard-form .input-grey legend {
  color: #212121;
}

.giftcard-form .input-grey button {
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s;
  height: min-content;
  padding-right: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: #45ab55;
}

.giftcard-form .loader {
  display: none;
  width: 33px;
  height: 30px;
  margin-right: 15px;
}

.giftcard-form .giftcard-block {
  display: none;
}

.giftcard-form .giftcard-block.visible {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.giftcard-form .giftcard-block-applied button {
  opacity: 1;
  visibility: visible;
}

.giftcard-form .giftcard-applied-code {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 22px 18px 22px 30px;
}

/* Sizes */

/* Sizes */

.select2-container {
  cursor: pointer;
}

.select2-container--open {
  z-index: 16;
}

.select2-container .select2-selection--single {
  height: auto;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #000000;
}

.select2-dropdown {
  border: 0;
  z-index: 1;
}

.select2-results__option {
  border-bottom: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}

.select2-container--select2-size {
  color: #38373d;
}

.select2-container--select2-size .select2-selection--single {
  border: 0;
}

.select2-container--select2-size .select2-selection--single .select2-selection__arrow {
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
}

.select2-container--select2-size .select2-results__option--highlighted[aria-selected] {
  border: 1px solid #9d897e;
  background: transparent;
  color: #38373d;
}

.select2-container--select2-size .select2-selection--single {
  width: 100%;
  padding: 6px;
  height: auto;
  outline: 1px solid transparent;
  background-color: #ffffff;
}

.select2-container--select2-size .select2-selection--single .select2-selection__arrow {
  position: absolute;
  height: 26px;
  width: 20px;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
}

.select2-container--select2-size .select2-selection--single .select2-selection__arrow b {
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
  border-color: #9d897e transparent transparent transparent;
}

.select2-container--select2-size.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #9d897e transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--select2-size .select2-img-txt {
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 10px;
}

.select2-container--select2-size .select2-img-txt img {
  width: 31px;
  height: 34px;
  margin-right: 10px;
}

.select2-container--select2-size.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #9d897e transparent;
}

.select2-container--select2-size .select2-results > .select2-results__options {
  max-height: 205px;
  box-shadow: 0px 15px 35px rgba(228, 209, 192, 0.5);
}

.select2-container--select2-default .select2-selection--single {
  height: 50px;
  background: #f8f8f8;
  border-radius: 0;
  padding: 16px 40px 18px 20px;
  border: 1px solid transparent;
}

.select2-container--select2-default .select2-selection--single:hover {
  border-color: #212121 !important;
}

.select2-container--select2-default .select2-selection--single .select2-selection__arrow {
  position: absolute;
  height: 26px;
  width: 20px;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.select2-container--select2-default .select2-selection--single .select2-selection__arrow b {
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #212121 transparent transparent transparent;
  position: absolute;
  left: 50%;
  top: 45%;
  transition: transform 0.2s;
}

.select2-container--select2-default .select2-selection--single .select2-selection__rendered {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.select2-container--select2-default .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  text-transform: none;
}

.select2-container--select2-default.select2-container--open .select2-selection__arrow b {
  transform: rotate(180deg);
}

.select2-container--select2-default .select2-dropdown {
  margin-top: 10px;
  box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.15);
}

.select2-container--select2-default .select2-results__option {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 30px;
  text-transform: uppercase;
  border: none;
  color: #212121;
  transition: background-color 0.2s linear;
}

.select2-container--select2-default .select2-results__option:hover {
  color: #c8c8c8;
}

select[data-select2-type="select2-default"].selected
+ .select2-container--select2-default
.select2-selection--single {
  border-color: #dadada;
}

.select2-container--select2-default.select2-container--focus .select2-selection--single {
  border-color: #212121 !important;
}

.select2-container--select2-rounded .select2-selection--single {
  height: auto;
  background: #ffffff;
  border-radius: 0;
  padding: 3px 0;
  border-radius: 20px;
}

.select2-container--select2-rounded .select2-selection--single .select2-selection__arrow {
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
}

.select2-container--select2-rounded .select2-selection--single .select2-selection__arrow {
  position: absolute;
  height: 24px;
  width: 24px;
  top: 10.5px;
  right: 4px;
  transform: translateY(-50%);
}

.select2-container--select2-rounded .select2-selection--single .select2-selection__arrow b {
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
  border-color: #9d897e transparent transparent transparent;
}

.select2-container--select2-rounded .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  font-weight: 700;
  white-space: break-spaces;
  text-transform: uppercase;
}

.select2-container--select2-rounded.select2-container--open .select2-selection--single {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--select2-rounded.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #9d897e transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--select2-rounded .select2-results {
  background-color: #f8f8f8;
  padding: 24px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 4px 10px 0px #74747424;
}

.select2-container--select2-rounded .select2-results__option {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  transition: all 0.3s linear;
  padding: 0;
  border: 0;
}

.select2-container--select2-rounded .select2-results__option:not(:last-child) {
  margin-bottom: 12px;
}

.select2-container--select2-rounded .select2-results__option:hover {
  font-weight: 700;
}

.select2-container--select2-grey-picker .select2-selection--single {
  height: auto;
  background: #f3f2ef;
  border-radius: 0;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 30px 0 30px;
}

.select2-container--select2-grey-picker .select2-selection--single .select2-selection__arrow {
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
}

.select2-container--select2-grey-picker .select2-selection--single .select2-selection__arrow {
  position: absolute;
  height: 26px;
  width: 20px;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.select2-container--select2-grey-picker .select2-selection--single .select2-selection__arrow b {
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
  border-color: #9d897e transparent transparent transparent;
}

.select2-container--select2-grey-picker .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  font-weight: 700;
  text-transform: uppercase;
}

.select2-container--select2-grey-picker .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
  font-weight: 400;
  text-transform: none;
  font-size: 12px;
}

.select2-container--select2-grey-picker.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #9d897e transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--select2-grey-picker .select2-results {
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.15);
}

.select2-container--select2-grey-picker .select2-results__option {
  padding: 8px 30px;
  text-transform: uppercase;
  transition: all 0.3s linear;
  border: 0;
  background: #f3f2ef;
}

.select2-container--select2-grey-picker .select2-results__option:hover {
  background: #9d897e;
  color: #ffffff;
}

.select2-container--select2-color .select2-selection--single {
  background: #ffffff;
  height: 50px;
  padding: 18px 35px 18px 12px;
  color: #212121;
  border: 1px solid #dadada;
  transition: border 0.2s;
  display: flex;
  align-items: center;
}

.select2-container--select2-color .select2-selection--single:hover {
  border-color: #212121;
}

.select2-container--select2-color .select2-selection--single .select2-selection__rendered {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 12px;
}

.select2-container--select2-color .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: normal;
  text-transform: none;
  padding-left: 4px;
}

.select2-container--select2-color .select2-selection--single .select2-selection__arrow {
  position: absolute;
  height: 26px;
  width: 20px;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.select2-container--select2-color .select2-selection--single .select2-selection__arrow b {
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #212121 transparent transparent transparent;
  position: absolute;
  left: 50%;
  top: 45%;
  transition: transform 0.2s;
}

.select2-container--select2-color.select2-container--focus .select2-selection--single {
  border-color: #212121;
}

.select2-container--select2-color.select2-container--open .select2-selection__arrow b {
  transform: rotate(180deg);
}

.select2-container--select2-color .select2-results__option {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 50px;
  gap: 12px;
  padding: 18px 35px 18px 12px;
  cursor: pointer;
}

.select2-container--select2-color .select2-results__option[aria-disabled="true"] {
  order: 1;
  opacity: 0.6;
  background-color: #e6e4de;
  cursor: not-allowed;
}

.select2-container--select2-color .select2-results {
  box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.15);
}

.select2-container--select2-color .select2-color-box {
  width: 24px;
  height: 24px;
  display: block;
}

.select2-container--select2-color .select2-results__options {
  display: grid;
  height: fit-content;
  max-height: 150px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #212121 transparent;
}

.select2-container--select2-search {
  border: 1px solid transparent;
}

.select2-container--select2-search .select2-selection--single {
  height: 50px !important;
  background: #f8f8f8;
  border-radius: 0;
  padding: 16px 40px 18px 20px;
}

.select2-container--select2-search .select2-selection--single .select2-selection__arrow {
  position: absolute;
  height: 26px;
  width: 20px;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.select2-container--select2-search .select2-selection--single .select2-selection__arrow b {
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #212121 transparent transparent transparent;
  position: absolute;
  left: 50%;
  top: 45%;
  transition: transform 0.2s;
}

.select2-container--select2-search .select2-selection--single .select2-selection__rendered {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.select2-container--select2-search .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  text-transform: none;
}

.select2-container--select2-search.select2-container--open .select2-selection__arrow b {
  transform: rotate(180deg);
}

.select2-container--select2-search .select2-dropdown {
  box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.15);
  margin-top: 1px;
}

.select2-container--select2-search .select2-dropdown.mobile-fullscreen {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh;
}

.select2-container--select2-search .select2-results__options {
  max-height: 255px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #212121 transparent;
}

.select2-container--select2-search .select2-results__options::-webkit-scrollbar {
  width: 3px;
}

.select2-container--select2-search .select2-results__options::-webkit-scrollbar-thumb {
  background-color: #212121;
}

.select2-container--select2-search .select2-results__options .select2-results__message {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  text-transform: none;
}

.select2-container--select2-search .select2-results__option {
  padding: 16px 20px;
  text-transform: uppercase;
  color: #212121;
}

.select2-container--select2-search .select2-results__option span:first-child {
  display: block;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.select2-container--select2-search .select2-results__option span:nth-child(2) {
  display: block;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-top: 4px;
}

.select2-container--select2-search .select2-search--dropdown {
  position: relative;
  padding: 0;
  border-bottom: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
  background-color: #ffffff;
}

.select2-container--select2-search .select2-search--dropdown::before {
  display: block;
  content: url("../../images/icons/search-grey.svg");
  position: absolute;
  top: 29%;
  left: 20px;
  height: 24px;
}

.select2-container--select2-search .select2-search--dropdown::after {
  display: none;
  content: url("../../images/icons/search-black.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  height: 24px;
}

.select2-container--select2-search .select2-search--dropdown input {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  padding: 16px 20px 16px 50px;
  background-color: #ffffff;
}

.select2-container--select2-search .select2-search--dropdown input::placeholder {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: #aaaaaa;
}

.select2-container--select2-search .select2-search--dropdown input::-webkit-textfield-decoration-container {
  background-color: #ffffff !important;
}

.select2-container--select2-search .select2-search--dropdown input::-webkit-textfield-decoration-container::-webkit-search-decoration {
  display: none !important;
}

.select2-container--select2-search .select2-search--dropdown input::-webkit-search-decoration {
  display: none !important;
}

.select2-container--select2-search .select2-search--dropdown .select-close-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  border: none;
  background-color: transparent;
}

.select2-container--select2-search .select2-search--dropdown .select-close-btn::before {
  display: block;
  content: url("../../images/icons/close-black.svg");
  height: 24px;
}

.select2-container--select2-search.select2-container--focus,
.select2-container--select2-search.select2-container--open,
.select2-container--select2-search:hover {
  border: 1px solid #212121;
}

select[data-select2-type="select2-search"].selected
+ .select2-container--select2-search
.select2-selection--single {
  border: 1px solid #dadada;
}

.select2-container--select2-volume .select2-selection--single {
  background: #ffffff;
  height: 50px;
  padding: 16px 40px 16px 16px;
  color: #212121;
  border: 1px solid #dadada;
  transition: border 0.2s;
  display: flex;
  align-items: center;
}

.select2-container--select2-volume .select2-selection--single:hover {
  border-color: #212121;
}

.select2-container--select2-volume .select2-selection--single .select2-selection__rendered {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.select2-container--select2-volume .select2-selection--single .select2-selection__arrow {
  position: absolute;
  height: 26px;
  width: 20px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.select2-container--select2-volume .select2-selection--single .select2-selection__arrow b {
  border-style: solid;
  border-width: 4px 3px 0 3px;
  border-color: #212121 transparent transparent transparent;
  position: absolute;
  left: 50%;
  top: 45%;
  transition: transform 0.2s;
}

.select2-container--select2-volume.select2-container--focus .select2-selection--single {
  border-color: #212121;
}

.select2-container--select2-volume.select2-container--open .select2-selection__arrow b {
  transform: rotate(180deg);
}

.select2-container--select2-volume .select2-results__option {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  height: 50px;
  padding: 16px;
  cursor: pointer;
}

.select2-container--select2-volume .select2-results {
  box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.15);
}

/* Sizes */

.res-message {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  max-width: 220px;
  bottom: 20px;
  left: 20px;
  border-radius: 5px;
  padding: 10px;
  z-index: 15;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.res-message p {
  color: #ffffff;
}

.res-message.error {
  background-color: #a52929;
}

.res-message.success {
  background-color: #45ab55;
}

.res-message.visible {
  visibility: visible;
  opacity: 1;
  animation: slideInLeft 0.5s;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

@keyframes diagonalBackgroundShift {
  25% {
    background-position: 100% 0   ,0 100%,100% 100%;
  }

  50% {
    background-position: 100% 0   ,0 0   ,100% 100%;
  }

  75% {
    background-position: 100% 0   ,0 0   ,0    100%;
  }

  100% {
    background-position: 100% 100%,0 0   ,0    100%;
  }
}

.animate__slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 1s;
}

.aroma-header__user-online {
  position: absolute;
  top: 45px;
  left: -5px;
  z-index: 1;
  box-shadow: 0px 15px 50px 0px #00000026;
  border-top: 1px solid #212121;
  transition-delay: 0.2s;
  width: 235px;
  padding: 24px;
}

.aroma-header__user-online::after {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  width: 40px;
  height: 22px;
  background: transparent;
  z-index: -1;
}

.aroma-header__user-some-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.aroma-header__user-some-info ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #dadada;
}

.aroma-header__user-some-info ul li {
  text-align: left;
}

.aroma-header__user-some-info ul li a {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #212121;
}

.aroma-header__user-some-info ul li a:hover {
  color: #c8c8c8;
}

.aroma-header__user-some-info ul li:nth-child(3) {
  width: 188px;
}

.loader,
.loader-del {
  width: 40px;
  height: 40px;
  border: 5px solid #aaaaaa;
  border-bottom-color: #212121;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: start;
}

.loader_overlay.active {
  visibility: visible;
  opacity: 1;
}

.loader_2 {
  position: sticky;
  top: 300px;
  width: 35px;
  aspect-ratio: 1;
  --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000);
  background: var(--_g) 0 0, var(--_g) 0 100%, var(--_g) 100% 100%;
  background-size: 40% 40%;
  animation: diagonalBackgroundShift 1s infinite linear;
}

.scroll-top-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #000000;
}

.scroll-top-btn__arrow {
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background: url("../../images/icons/arrow_top_black.svg") center center no-repeat, #f8f8f8;
  transition: all 0.3s ease-in;
}

.scroll-top-btn:hover {
  color: #000000;
}

.scroll-top-btn:hover .scroll-top-btn__arrow {
  background: url("../../images/icons/arrow_top_black.svg") center center no-repeat, #f3f2ef;
}

.small-product-item-info {
  display: flex;
  align-items: center;
  width: 100%;
  background: #f3f2ef;
  padding: 8px;
  border-radius: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.small-product-item-info__title {
  font-weight: 400;
  margin-bottom: 10px;
}

.small-product-item-info__content {
  margin-top: 30px;
  margin-bottom: 30px;
}

.small-product-item-info__txt-holder {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
}

.small-product-item-info__img-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  overflow: hidden;
  flex: 0 0 auto;
  width: 65px;
  height: 65px;
}

.small-product-item-info__img-wrap img {
  object-fit: contain;
}

.small-product-item-info__brand {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #212121;
  padding-right: 15px;
  margin-right: 15px;
  position: relative;
}

.small-product-item-info__brand:after {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 30px;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  background: #c8c8c8;
}

.small-product-item-info__desc {
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  align-items: center;
}

.small-product-item-info__amount {
  margin-left: auto;
  padding-right: 10px;
  padding-left: 15px;
  position: relative;
  font-weight: 400;
  flex: 0 0 auto;
}

.small-product-item-info__amount:before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 30px;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  background: #c8c8c8;
}

.modal-window {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #212121b3;
  z-index: 20;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-window.active {
  visibility: visible;
  opacity: 1;
}

.modal-window__wrapper {
  width: 552px;
  height: 164px;
  background: #ffffff;
}

.modal-window__top {
  position: relative;
  padding: 20px 18px;
  text-align: center;
}

.modal-window__top .aroma-ico-close_big {
  position: absolute;
  top: 22px;
  right: 22px;
  font-size: 16px;
  cursor: pointer;
}

.modal-window__content {
  display: flex;
  justify-content: center;
  padding: 30px 33px;
  text-align: center;
}

.modal-window__content p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.language-change__modal {
  display: none;
  position: fixed;
  top: 51px;
  right: 40px;
  z-index: 10;
  width: 234px;
  height: 135px;
  padding: 18px 24px;
  text-align: center;
  background: #212121;
}

.language-change__modal.show {
  display: block;
}

.language-change__modal-title {
  position: relative;
  display: inline-block;
  padding-left: 25px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  text-transform: uppercase;
}

.language-change__modal-title::before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  background: url("../../images/icons/ukraine_flag.svg") no-repeat center;
}

.language-change__modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px auto;
  padding: 6px 0;
  background: #ffffff;
}

.language-change__modal-btn span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.03;
  color: #000000;
}

.language-change__modal-text {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  color: #aaaaaa;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .cities {
    width: 181px;
    margin-left: 20px;
    margin-right: 0;
  }

  .cities::after {
    display: none !important;
  }
}

@media (min-width: 1201px) {
  .aroma-footer__logo {
    margin: 40px 51px 0 10px;
  }

  .aroma-footer__logo a > img {
    width: 173px;
    height: 85px;
  }

  .aroma-footer__links-wrap ul {
    max-width: 214px;
  }

  .aroma-footer__links-wrap ul li:not(:last-child) {
    margin-bottom: 10px;
  }

  .aroma-footer__copyright {
    padding: 24px 0;
  }

  .aroma-footer__copyright span:first-child {
    margin-right: 30px;
  }

  .cities {
    width: 135px;
    margin-left: 0;
    margin-right: 80px;
  }
}

@media (max-width: 1200px) {
  .aroma-footer__column .line {
    display: none;
  }

  .aroma-footer__copyright .txt__small-addition {
    margin-right: 50px;
  }

  .aroma-footer__row {
    flex-wrap: wrap;
  }

  .aroma-footer__column {
    width: auto;
    padding: 30px 0;
  }

  .aroma-footer__logo {
    margin-top: 30px;
  }

  .aroma-footer__logo a > img {
    width: 121px;
    height: 60px;
  }

  .aroma-footer__logo,
  .aroma-footer__socials {
    border-bottom: 1px solid #ffffff;
  }

  .aroma-footer__column:first-child {
    border-bottom: 1px solid #ffffff;
  }

  .aroma-footer__links {
    display: flex;
    position: relative;
    gap: 40px;
  }

  .aroma-footer__links:nth-of-type(2) {
    border: none;
    padding-left: 0;
  }

  .aroma-footer__links:nth-of-type(3) {
    padding-right: 0;
  }

  .aroma-footer__links-wrap:first-child {
    max-width: 225px;
  }

  .aroma-footer__links-wrap:first-child::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 245px;
    width: 1px;
    height: 184px;
    background-color: #ffffff;
  }

  .aroma-footer__links-wrap ul {
    width: 160px;
  }

  .aroma-footer__logo,
  .aroma-footer__socials {
    width: 50%;
  }

  .aroma-footer__logo {
    order: -2;
  }

  .aroma-footer__socials {
    order: -1;
    display: flex;
    justify-content: flex-end;
    border-left: 0;
  }

  .aroma-footer__socials .socials-list {
    height: 60px;
  }

  .aroma-footer__socials .socials-list li {
    height: 60px;
  }

  .aroma-footer__copyright {
    padding: 33px 0 34px;
  }

  .aroma-footer__copyright span:first-child {
    margin-right: 30px;
  }

  .aroma-popup__list .basket__list-item {
    padding-bottom: 25px;
  }

  .aroma-popup__list .basket__list-item-fav-btn span,
  .aroma-popup__list .basket__list-item-del-btn span {
    display: none;
  }

  .aroma-header__mn-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    opacity: 70%;
    background: #212121;
  }

  .aroma-header__phone-and-lang-changer {
    align-items: center;
    padding: 27px 20px;
  }

  .aroma-header__phone-and-lang-changer .wishlist-and-cross {
    display: flex;
    gap: 16px;
  }

  .aroma-header__phone-and-lang-changer .wishlist-and-cross .wishlist,
  .aroma-header__phone-and-lang-changer .wishlist-and-cross .cross {
    width: 18px;
    height: 18px;
  }

  .aroma-header__phone-and-lang-changer .wishlist-and-cross .wishlist-btn,
  .aroma-header__phone-and-lang-changer .wishlist-and-cross .cross-btn {
    display: block;
    width: 100%;
    height: 100%;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__phone-icon {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 0;
    height: 24px;
    margin-right: 22px;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__phone-icon a {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.03em;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__phone-icon .aroma-header__btn-img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__language-change {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 18px;
    margin-right: auto;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__language-change a,
  .aroma-header__phone-and-lang-changer .aroma-header__language-change span {
    display: none;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #b8b6ae;
    order: 1;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__language-change a.show,
  .aroma-header__phone-and-lang-changer .aroma-header__language-change span.show {
    display: block;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__language-change a:active,
  .aroma-header__phone-and-lang-changer .aroma-header__language-change span:active {
    color: #212121;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__language-change a.active,
  .aroma-header__phone-and-lang-changer .aroma-header__language-change span.active {
    color: #212121;
    order: 0;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__language-change a.active::after,
  .aroma-header__phone-and-lang-changer .aroma-header__language-change span.active::after {
    position: absolute;
    top: 50%;
    right: -10px;
    display: block;
    content: "";
    width: 6px;
    height: 3px;
    background: url("../../images/icons/arrow_s.svg") no-repeat center;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__language-change a:first-child,
  .aroma-header__phone-and-lang-changer .aroma-header__language-change span:first-child {
    position: relative;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__language-change a:last-child,
  .aroma-header__phone-and-lang-changer .aroma-header__language-change span:last-child {
    bottom: 0;
    position: relative;
    margin-left: 0;
  }

  .aroma-header__user-online {
    top: 52px;
  }

  .loader_2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .language-change__modal {
    top: 58px;
    right: 20px;
  }
}

@media (max-width: 1200px) and (max-width: 767px) {
  .aroma-footer__links {
    gap: 0;
  }

  .aroma-footer__links-wrap:first-child::after {
    display: none;
  }

  .aroma-header__phone-and-lang-changer .wishlist-and-cross {
    display: none;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__phone-icon {
    margin-right: 0;
  }
}

@media (max-width: 1200px) and (orientation: landscape) and (max-height: 600px) {
  .aroma-popup .aroma-popup__list {
    height: auto;
  }
}

@media (max-width: 1000px) {
  .aroma-footer__copyright .txt__small-addition {
    margin-right: 150px;
  }
}

@media (max-width: 767px) {
  .aroma-footer__copyright .txt__small-addition {
    margin-right: 0;
  }

  .aroma-footer__column {
    width: auto;
    border: none;
    padding: 24px 0;
  }

  .aroma-footer__logo {
    margin-top: 22px;
  }

  .aroma-footer__logo a > img {
    width: 108px;
    height: 54px;
  }

  .aroma-footer__socials {
    padding: 23px 0;
    width: 50%;
    border-bottom: 1px solid #ffffff;
  }

  .aroma-footer__socials .socials-list {
    height: 52px;
  }

  .aroma-footer__socials .socials-list li {
    height: 52px;
  }

  .aroma-footer__socials .socials-list__link {
    width: 52px;
    height: 52px;
  }

  .aroma-footer__column:first-child {
    border-bottom: 1px solid #ffffff;
  }

  .aroma-footer__links {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }

  .aroma-footer__links:nth-of-type(2n) {
    border: none;
    padding-right: 10px;
  }

  .aroma-footer__links:nth-of-type(3) {
    order: 1;
  }

  .aroma-footer__links-wrap {
    display: flex;
    flex-direction: column;
  }

  .aroma-footer__links-wrap > span {
    margin-bottom: 10px;
  }

  .aroma-footer__links-wrap ul {
    margin-left: 0;
  }

  .aroma-footer__links-wrap:first-child {
    margin-bottom: 20px;
  }

  .aroma-footer__copyright {
    padding: 20px 0;
  }

  .aroma-footer__copyright > span {
    width: 100%;
    margin-bottom: 12px;
  }

  .cities ul {
    width: 107px;
  }

  .aroma-popup__wrap {
    right: 0;
    transition: none;
  }

  .aroma-popup__wrapper {
    max-width: 320px;
  }

  .aroma-popup__wrapper.availability {
    margin: 60px auto;
  }

  .aroma-popup__title-center {
    padding: 15px;
  }

  .aroma-popup__list {
    margin-right: 0;
    max-height: calc(100vh - 377px);
  }

  .aroma-popup__list-item {
    padding: 28px 20px;
    grid-template-columns: 100px minmax(auto, 1fr) auto;
  }

  .aroma-popup__list-item-img img {
    width: 100px;
    height: 100px;
  }

  .aroma-popup__list-item-btns {
    right: 20px;
  }

  .aroma-popup__list .basket__list-item {
    grid-template-columns: 118px auto;
    padding-bottom: 0;
  }

  .aroma-popup__list .basket__list-item:not(:last-child) {
    margin-bottom: 25px;
  }

  .aroma-popup__list .basket__list-item-img {
    grid-row: 1 / 2;
    margin-right: 10px;
  }

  .aroma-popup__list .basket__list-item-img img,
  .aroma-popup__list .basket__list-item-img source {
    width: 100px;
    height: 100px;
  }

  .aroma-popup__list .basket__list-item-bottom {
    grid-column: 1 / 3;
  }

  .aroma-popup__list .basket__list-item-subtitle {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    margin: 6px 0 0;
  }

  .aroma-popup__list .basket__list-item-descr {
    font-size: 12px;
  }

  .aroma-popup__list .basket__list-item-control {
    height: 36px;
    gap: 5px;
  }

  .aroma-popup__list .basket__list-item-volume .select2-container--select2-volume .select2-selection--single {
    height: 36px;
  }

  .aroma-popup__list .basket__list-item-quantity {
    width: 85px;
  }

  .aroma-popup__list .basket__list-item-price-old {
    font-weight: 300;
  }

  .aroma-popup__list .basket__list-item-price-new {
    font-weight: 300;
  }

  .aroma-popup__info {
    padding: 30px 20px;
    position: relative;
  }

  .aroma-popup__info-btns {
    position: sticky;
    bottom: 29px;
  }

  .aroma-popup__info .mobile-hidden {
    display: none;
  }

  .aroma-popup__content .buy-in-one-click__form {
    padding: 30px;
  }

  .aroma-popup.active .aroma-popup__empty-cart .empty-basket__subtitle {
    margin: 16px 0 5px;
  }

  .aroma-popup.active .aroma-popup__empty-cart {
    padding: 0 20px;
    text-align: center;
  }

  .auth-form .aroma-popup__wrapper {
    margin: unset;
    margin-bottom: 100px;
  }

  .auth-form__wrapper {
    padding: 0 20px 30px 20px;
  }

  .auth-form__tabs-control {
    padding: 0;
  }

  .auth-form__tabs-control button:first-of-type {
    width: 40%;
  }

  .auth-form__tabs-control button:last-of-type {
    width: 60%;
  }

  .auth-form .login-form .login-form__resend-block {
    margin-top: 30px;
  }

  .auth-form .login-form .login-form__resend-block p {
    margin-bottom: 20px;
  }

  .auth-form .register-form__code-block .checkbox-default:last-of-type {
    margin-bottom: 35px;
  }

  .auth-form .register-form__resend-block {
    margin-top: 30px;
  }

  .auth-form .register-form__resend-block p {
    margin-bottom: 20px;
  }

  .auth-form .input-grey input {
    height: 60px;
  }

  .auth-form .btn-black {
    height: 60px;
  }

  .aroma-popup.auth-form {
    top: 57px;
  }

  .promocode-form .promocode-applied-code {
    height: 50px;
  }

  .giftcard-form .giftcard-applied-code {
    height: 50px;
  }

  .cart-popup .aroma-popup__info .btn-white {
    position: sticky;
    bottom: 98px;
  }

  .select2-dropdown {
    z-index: 4;
  }

  .select2-container--select2-rounded .select2-results {
    padding: 24px 20px;
  }

  .select2-container--select2-rounded .select2-results__option {
    font-size: 12px;
    line-height: 15px;
  }

  .select2-container--select2-search .select2-dropdown {
    display: none;
  }

  .select2-container--select2-search .select2-results__options {
    max-height: calc(100vh - 70px);
  }

  .select2-container--select2-search .select2-results__option {
    border: none;
  }

  .select2-container--select2-search .select2-search--dropdown::before {
    display: none;
  }

  .select2-container--select2-search .select2-search--dropdown::after {
    display: block;
  }

  .select2-container--select2-search .select2-search--dropdown input {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    padding: 20px 50px 20px 65px;
  }

  .select2-container--select2-search .select2-search--dropdown input::placeholder {
    font-size: 22px;
    line-height: 28px;
  }

  .select2-container--select2-volume .select2-selection--single {
    height: 40px;
    padding: 12px 40px 12px 8px;
  }

  .select2-container--select2-volume .select2-selection--single .select2-selection__rendered {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    text-transform: none;
  }

  .select2-container--select2-volume .select2-results__option {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    text-transform: none;
    height: 40px;
    padding: 12px 8px;
  }

  .res-message {
    left: 15px;
    bottom: 20px;
  }

  .res-message p {
    font-size: 14px;
  }

  .aroma-header__phone-and-lang-changer {
    justify-content: space-between;
    padding: 22px 20px;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__language-change {
    flex-direction: row;
    gap: 12px;
    margin-right: 0;
    padding-left: 13px;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__language-change::before {
    position: absolute;
    content: "";
    top: -4px;
    left: 0;
    height: 26px;
    width: 1px;
    background-color: #dadada;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__language-change a {
    display: block;
  }

  .aroma-header__phone-and-lang-changer .aroma-header__language-change a,
  .aroma-header__phone-and-lang-changer .aroma-header__language-change span.active::after {
    content: none;
  }

  .aroma-header__user-online {
    top: 40px;
  }

  .loader,
  .loader-del {
    width: 25px;
    height: 25px;
  }

  .small-product-item-info__txt-holder {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .small-product-item-info__img-wrap {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .small-product-item-info__img-wrap {
    width: 58px;
    height: 58px;
    align-self: flex-start;
  }

  .small-product-item-info__brand {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .small-product-item-info__brand:after {
    display: none;
  }

  .small-product-item-info__desc {
    grid-column: 2/3;
    grid-row: 1/2;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .small-product-item-info__separator {
    display: none;
  }

  .small-product-item-info__amount {
    grid-column: 2/3;
    grid-row: 2/3;
    margin-left: 0;
    padding-left: 0;
    margin-top: 18px;
  }

  .small-product-item-info__amount:before {
    display: none;
  }

  .small-product-item-info {
    display: grid;
    grid-template-columns: 78px auto;
    grid-template-rows: auto auto;
    border-radius: 35px;
    padding: 12px;
  }

  .modal-window__wrapper {
    width: 320px;
    height: 180px;
  }

  .modal-window__top {
    padding: 20px 18px;
  }

  .modal-window__content {
    align-items: center;
    padding: 30px;
  }

  .language-change__modal {
    top: 47px;
    right: initial;
    left: 20px;
    width: 199px;
    height: 113px;
    padding: 20px;
  }

  .language-change__modal-title {
    font-size: 10px;
    line-height: 12px;
    padding-left: 22px;
  }

  .language-change__modal-title::before {
    width: 12px;
    height: 12px;
  }

  .language-change__modal-btn {
    margin: 12px 0;
    padding: 3px 0;
  }

  .language-change__modal-btn span {
    font-size: 10px;
  }

  .language-change__modal-text {
    font-size: 12px;
  }
}

@media all and (max-width: 767px) {
  .aroma-popup__content_add-to-aromateka .aromateka-describer__column {
    width: 50%;
  }

  .aroma-popup__content_add-to-aromateka .aromateka-describer__column_fw {
    width: 100%;
  }

  .aroma-popup__content_add-to-aromateka .aromateka-describer__column_fw ul li {
    width: 50%;
  }

  .aroma-popup__content .contact-data-in-popup {
    margin-top: 40px;
  }

  .aroma-popup__content .recomendation-in-popup__title {
    text-align: center;
  }

  .aroma-popup__content .ask-consultant-in-popup {
    flex-direction: column-reverse;
  }

  .aroma-popup__content .ask-consultant-in-popup__column {
    width: 100%;
    margin-bottom: 20px;
  }

  .aroma-popup .availability__title {
    text-align: left;
    padding: 0 20px;
  }

  .aroma-popup .availability__subtitle {
    padding: 0 20px;
  }

  .aroma-popup .availability__stores {
    padding: 0 20px;
  }
}

@media (min-width: 1201px) and (max-width: 1218px) {
  .aroma-footer__socials {
    padding: 45px 10px 0 27px;
  }
}

@media (max-width: 1200px) and (min-width: 768px) {
  .aroma-header__phone-and-lang-changer .aroma-header__phone-icon .aroma-header__btn-img {
    display: none;
  }
}